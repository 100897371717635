import React, { createContext, useState, useEffect } from 'react';
import moon from "../../Images/moon.gif";

// Create the ThemeContext
export const ThemeContext = createContext();

const getThemeFromStorage = () => {
    let theme = true;
    if (localStorage.getItem('theme')) {
        theme = JSON.parse(localStorage.getItem('theme'));
    }
    return theme;
};

// Create the ThemeProvider component
export const ThemeProvider = ({ children }) => {
    const [isLightMode, setIsLightMode] = useState(getThemeFromStorage());

    const handleDarkmode = () => {
        setIsLightMode(prevMode => !prevMode);
    };

    useEffect(() => {
        const body = document.querySelector('body');
        body.className = isLightMode ? 'light-theme' : 'dark-theme';
        localStorage.setItem('theme', JSON.stringify(isLightMode));
        // Optionally call sendingModule if needed
        // sendingModule(isLightMode);
    }, [isLightMode]);

    return (
        <ThemeContext.Provider value={{ isLightMode, handleDarkmode }}>
            {children}
        </ThemeContext.Provider>
    );
};
