import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import "./Subscription.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Button } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import Web3 from 'web3';
import erc20Abi from '../../erc20Abi.json'
import consts from "../../constant";
import toast from "react-hot-toast";
import LoadingButton from '@mui/lab/LoadingButton';
import Axios from "../Axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Subscription = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const web3 = new Web3(window.ethereum)

  const [status, setStatus] = useState(false)
  const [planId, setPlanId] = useState("")
  const subscribe = async (value, id) => {
    try {
      setStatus(true)
      var erc20Instance = new web3.eth.Contract(
        erc20Abi,
        consts?.contractAddress
      )
      var decimal = await erc20Instance.methods.decimals().call()
      var balance = await erc20Instance.methods.balanceOf(window.localStorage.getItem("Address")).call()
      var bal = (balance / (10 ** decimal))
      console.log("🚀 ~ subscribe ~ bal:", bal, value)
      if (balance == 0) {
        setStatus(false)
        toast.error("You Dont have a Balance")
      } else if (value > bal) {
        setStatus(false)
        toast.error("Insufficient Balance")
      } else {
        var amount = (value * (10 ** decimal))
        var transfer = await erc20Instance.methods.transfer(consts.ownerAddress, amount?.toString()).send({ from: window.localStorage.getItem("Address") })
        console.log("🚀 ~ subscribe ~ transfer:", transfer)
        if (transfer) {
          const { data } = await Axios.post('/users/subscribePlan', {
            plan_id: id,
            Transaction: transfer
          }, {
            headers: {
              Authorization: window.localStorage.getItem('Rikosta')
            }
          })
          if (data?.success == true) {
            toast.success("Successfully Subscribed")
            getSubscibedPlan()
          }
        } else {
          toast.error("Failed to subscribe")
        }
        setStatus(false)
      }
    } catch (error) {
      setStatus(false)
      toast.error("Failed to subscribe")
      console.log("🚀 ~ subscribe ~ error:", error)
    }
  }


  const [plan, setPlan] = useState([])

  const getPlan = async () => {
    try {
      const { data } = await Axios.get('/admin/getPlan', {
        headers: {
          Authorization: window.localStorage.getItem('Rikosta')
        }
      })
      console.log("🚀 ~ getPlan ~ data:", data)
      setPlan(data?.result)
    } catch (error) {
      console.log("🚀 ~ getPlan ~ error:", error)
    }
  }

  const getSubscibedPlan = async () => {
    try {
      const { data } = await Axios.get('/users/subscribedPlan', {
        headers: {
          Authorization: window.localStorage.getItem('Rikosta')
        }
      })
      if (data?.success == true) {
        setStatus(true)
        setPlanId(data?.result)
      }
    } catch (error) {
      console.log("🚀 ~ getSubscibedPlan ~ error:", error)
    }
  }

  useEffect(() => {
    getPlan()
    getSubscibedPlan()
  }, [])



  return (
    <div className="subscription-full">
      <Header />
      <div className="contain-width">
        {/* <h1>Subscription</h1> */}
        <Box>
          <Grid item container spacing={0}>
            <Grid item size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
              <div className="purchase-detail text-center">
                <h1>Purchase a subscription</h1>
                <p>Choose the plan that works for you.</p>
              </div>
            </Grid>

            <Box sx={{ width: "100%" }}>
              <div className="tab-head">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Monthly" {...a11yProps(0)} />
                </Tabs>
              </div>

              <CustomTabPanel value={value} index={0} className="tab-content">
                {/* <div>
                  <Grid item container spacing={2}>
                    {rows.map((row, ind) => {
                      return (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                          <div className="plan-card">
                        <h5>{row.head}</h5>
                        <div className="display-1">
                          <h3>{row.text}</h3>
                          <p>{row.para}</p>
                        </div>
                        <List className="list-part">
                          <ListItem className="list-icon display-1"> <DoneIcon/> Lorem ipsum dolor sit amet</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon/> Lorem ipsum dolor sit amet consectetur. </ListItem> 
                          <ListItem className="list-icon display-1"> <DoneIcon/> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon/> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon/> Lorem ipsum dolor sit</ListItem>
                        </List>

                        <div className="plan-btn">
                            <Button>Choose Plan</Button>
                        </div>
                      </div>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </div> */}

                <div className="plan-details">
                  <Grid item container spacing={1}>
                    {
                      plan?.length > 0 && plan?.map((row, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={index} >
                            <div className="plan-card">
                              <h5>{row?.Title}</h5>
                              <div className="display-1">
                                <h3>{row?.Currency} {row?.Amount}</h3>
                                <p>per {row?.Duration} {row?.type}</p>
                              </div>
                              <List className="list-part">
                                <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit amet</ListItem>
                                <ListItem className="list-icon display-1"> <DoneIcon />
                                  Lorem ipsum dolor sit amet consectetur.
                                </ListItem>
                                <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                                <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                                <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                              </List>

                              <div className="plan-btn">
                                <Button disabled={status} onClick={() => { subscribe(row?.Amount, row?._id) }} > {row?._id == planId ? "Subscribed" : " Choose Plan"}</Button>
                              </div>
                            </div>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </div>
              </CustomTabPanel>
              {/* 
              <CustomTabPanel value={value} index={1}>
                <div className="plan-details">
                  <Grid item container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="plan-card">
                        <h5>Basic Plan</h5>
                        <div className="display-1">
                          <h3>$0</h3>
                          <p>per editor/month billed yearly as $0</p>
                        </div>
                        <List className="list-part">
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit amet</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon />
                            Lorem ipsum dolor sit amet consectetur.
                          </ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                        </List>

                        <div className="plan-btn">
                          <Button>Choose Plan</Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="plan-card">
                        <h5>Stander Plan</h5>
                        <div className="display-1">
                          <h3>$28</h3>
                          <p>per editor/month billed yearly as $576</p>
                        </div>
                        <List className="list-part">
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit amet</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon />
                            Lorem ipsum dolor sit amet consectetur.
                          </ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                        </List>

                        <div className="plan-btn">
                          <Button>Choose Plan</Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="plan-card">
                        <h5>Pro Plan</h5>
                        <div className="display-1">
                          <h3>$46</h3>
                          <p>per editor/month billed yearly as $1152</p>
                        </div>
                        <List className="list-part">
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit amet</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon />
                            Lorem ipsum dolor sit amet consectetur.
                          </ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                        </List>

                        <div className="plan-btn">
                          <Button>Choose Plan</Button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <div className="plan-card">
                        <h5>Unlimited Plan</h5>
                        <div className="display-1">
                          <h3>$94</h3>
                          <p>per editor/month billed yearly as $288</p>
                        </div>
                        <List className="list-part">
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit amet</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon />
                            Lorem ipsum dolor sit amet consectetur.
                          </ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                          <ListItem className="list-icon display-1"> <DoneIcon /> Lorem ipsum dolor sit</ListItem>
                        </List>

                        <div className="plan-btn">
                          <Button>Choose Plan</Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </CustomTabPanel> */}
            </Box>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Subscription;
