import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import './Profile.css'
import { Button, Grid2, TextField } from '@mui/material'
import Header from '../Header/Header'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import profimg from '../../Images/prfimg.png'
// import React, { useState } from "react";
import Switch from '@mui/material/Switch';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import stck from '../../Images/stacks.png'
import nem from '../../Images/nem.webp'
import btc from '../../Images/btc.webp'
import Subscription from '../Subscription/Subscription';
import Axios from '../Axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment'
import { QRCodeSVG } from "qrcode.react";
// import { QRCode } from "react-qr-svg";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--clr-bg)',
  // border: '2px solid #000',
  // boxShadow: 24,
  borderRadius: '12px',
  p: 4,
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function Profile() {

  const location = useLocation()

  const theme = useTheme();
  var mdScreen = useMediaQuery(theme.breakpoints.up('md'));

  // const [chk, setChk] = useState(mdScreen)

  const [value, setValue] = useState(0);
  const [mobTab, setMobTab] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (!mdScreen) {
      setMobTab(!mobTab)
    }
  };

  useEffect(() => {
    if (!mdScreen) {
      setMobTab(!mobTab)
    } else {
      if (location.state == "1") {
        setValue(1)
      } else {
        setValue(0)
        setMobTab(false)
      }
    }
  }, [mdScreen])

  // console.log(selected, 'selected');
  const [userData, setUserData] = useState({})
  const [sessionData, setSessionData] = useState([])
  const [nameErr, setNameErr] = useState(null)

  const [oldpassword, setOldpassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [oldpasswordErr, setOldpasswordErr] = useState('')
  const [newPasswordErr, setNewPasswordErr] = useState('')
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('')

  const [secret, setSecret] = useState(null)
  const [qrCode, setQrCode] = useState(null)
  const [secret1Err, setSecret1Err] = useState("")
  const [secret1, setSecret1] = useState("")
  const [copy, setCopy] = useState(false)


  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

  const checkpass = (e) => {
    if (!passwordRegex.test(e)) {
      setNewPasswordErr("Password Must Contain MIN 8 Character , One ALPHABET And Must Contain One Number ")
    } else {
      setNewPasswordErr("")
    }

  }

  const getProfile = async () => {
    try {
      const { data } = await Axios.get('/getProfile', {}, {
        headers: {
          Authorization: localStorage.getItem('Rikosta')
        }
      })
      if (data.success) {
        setUserData(data.result)
      }
      else {
        setUserData({})
      }
    } catch (error) {
      console.log(error, 'er');

    }
  }

  const getSession = async () => {
    try {
      const { data } = await Axios.post('/getSession', {}, {
        headers: {
          Authorization: localStorage.getItem('Rikosta')
        }
      })
      if (data.success) {
        setSessionData(data.result)
      }
      else {
        setSessionData([])
      }
    } catch (error) {
      console.log(error, 'err');

    }
  }

  useEffect(() => {
    getProfile()
    // getSession()
  }, [])

  const [prfContent, setPrfContent] = useState([
    {
      name: <div className='display-1'>

        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8633 16.8971C10.9117 16.8971 6.89758 20.9112 6.89758 25.8628V28.4245C6.89758 29.1319 6.32414 29.7053 5.61676 29.7053C4.90938 29.7053 4.33594 29.1319 4.33594 28.4245V25.8628C4.33594 19.4964 9.49693 14.3354 15.8633 14.3354C22.2297 14.3354 27.3907 19.4964 27.3907 25.8628V28.4245C27.3907 29.1319 26.8173 29.7053 26.1099 29.7053C25.4025 29.7053 24.8291 29.1319 24.8291 28.4245V25.8628C24.8291 20.9112 20.815 16.8971 15.8633 16.8971Z" fill="#FCFCFD" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8627 14.3356C18.6922 14.3356 20.986 12.0418 20.986 9.21228C20.986 6.38276 18.6922 4.08899 15.8627 4.08899C13.0332 4.08899 10.7394 6.38276 10.7394 9.21228C10.7394 12.0418 13.0332 14.3356 15.8627 14.3356ZM15.8627 16.8972C20.1069 16.8972 23.5476 13.4565 23.5476 9.21228C23.5476 4.968 20.1069 1.52734 15.8627 1.52734C11.6184 1.52734 8.17773 4.968 8.17773 9.21228C8.17773 13.4565 11.6184 16.8972 15.8627 16.8972Z" fill="#FCFCFD" />
        </svg>
        <div className="">Profile</div>
      </div>
    },
    {
      name: <div className='display-1'>

        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_126_234)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1096 8.53381H5.61645C4.20169 8.53381 3.05481 9.6807 3.05481 11.0955V21.342C3.05481 22.7568 4.2017 23.9037 5.61645 23.9037H26.1096C27.5244 23.9037 28.6712 22.7568 28.6712 21.342V11.0955C28.6712 9.6807 27.5244 8.53381 26.1096 8.53381ZM5.61645 5.97217C2.78694 5.97217 0.493164 8.26594 0.493164 11.0955V21.342C0.493164 24.1715 2.78694 26.4653 5.61645 26.4653H26.1096C28.9391 26.4653 31.2329 24.1715 31.2329 21.342V11.0955C31.2329 8.26594 28.9391 5.97217 26.1096 5.97217H5.61645Z" fill="#777E91" />
            <path d="M20.9861 13.6573C20.9861 12.9499 21.5595 12.3765 22.2669 12.3765H24.8285C25.5359 12.3765 26.1094 12.9499 26.1094 13.6573C26.1094 14.3647 25.5359 14.9381 24.8285 14.9381H22.2669C21.5595 14.9381 20.9861 14.3647 20.9861 13.6573Z" fill="#777E91" />
            <path d="M5.61621 18.7806C5.61621 18.0732 6.18965 17.4998 6.89703 17.4998C7.60441 17.4998 8.17785 18.0732 8.17785 18.7806C8.17785 19.488 7.60441 20.0614 6.89703 20.0614C6.18965 20.0614 5.61621 19.488 5.61621 18.7806Z" fill="#777E91" />
            <path d="M10.7395 18.7806C10.7395 18.0732 11.3129 17.4998 12.0203 17.4998H19.7053C20.4126 17.4998 20.9861 18.0732 20.9861 18.7806C20.9861 19.488 20.4126 20.0614 19.7053 20.0614H12.0203C11.3129 20.0614 10.7395 19.488 10.7395 18.7806Z" fill="#777E91" />
            <path d="M24.8285 17.4998C24.1212 17.4998 23.5477 18.0732 23.5477 18.7806C23.5477 19.488 24.1212 20.0614 24.8285 20.0614C25.5359 20.0614 26.1094 19.488 26.1094 18.7806C26.1094 18.0732 25.5359 17.4998 24.8285 17.4998Z" fill="#777E91" />
            <path d="M17.1436 12.3765C16.4362 12.3765 15.8628 12.9499 15.8628 13.6573C15.8628 14.3647 16.4362 14.9381 17.1436 14.9381C17.851 14.9381 18.4244 14.3647 18.4244 13.6573C18.4244 12.9499 17.851 12.3765 17.1436 12.3765Z" fill="#777E91" />
            <path d="M10.7395 13.6573C10.7395 12.9499 11.3129 12.3765 12.0203 12.3765C12.7277 12.3765 13.3011 12.9499 13.3011 13.6573C13.3011 14.3647 12.7277 14.9381 12.0203 14.9381C11.3129 14.9381 10.7395 14.3647 10.7395 13.6573Z" fill="#777E91" />
            <path d="M6.89703 12.3765C6.18965 12.3765 5.61621 12.9499 5.61621 13.6573C5.61621 14.3647 6.18965 14.9381 6.89703 14.9381C7.60441 14.9381 8.17785 14.3647 8.17785 13.6573C8.17785 12.9499 7.60441 12.3765 6.89703 12.3765Z" fill="#777E91" />
          </g>
          <defs>
            <clipPath id="clip0_126_234">
              <rect width="30.7397" height="30.7397" fill="white" transform="translate(0.493164 0.849121)" />
            </clipPath>
          </defs>
        </svg>

        <div className="" >Subscription</div>
      </div>
    },
    {
      name: <div className='display-1' onClick={() => { getSession() }}>

        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.89758 7.78742L6.89758 19.3148L24.8291 19.3148L24.8291 7.78742C24.8291 7.08005 24.2556 6.5066 23.5483 6.5066L8.1784 6.5066C7.47102 6.5066 6.89758 7.08005 6.89758 7.78742ZM4.33594 21.8765L27.3907 21.8765L27.3907 7.78742C27.3907 5.66529 25.6704 3.94496 23.5483 3.94496L8.1784 3.94496C6.05627 3.94496 4.33594 5.66529 4.33594 7.78742L4.33594 21.8765Z" fill="#777E91" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.33606 21.8762L4.33606 23.157C4.33606 23.8644 4.9095 24.4379 5.61688 24.4379L26.11 24.4379C26.8174 24.4379 27.3909 23.8644 27.3909 23.157L27.3909 21.8762L4.33606 21.8762ZM1.77441 23.157C1.77441 25.2792 3.49474 26.9995 5.61688 26.9995L26.11 26.9995C28.2322 26.9995 29.9525 25.2792 29.9525 23.157L29.9525 20.5954C29.9525 19.888 29.3791 19.3146 28.6717 19.3146L3.05524 19.3146C2.34786 19.3146 1.77441 19.888 1.77441 20.5954L1.77441 23.157Z" fill="#777E91" />
        </svg>

        <div className="" >Sessions & login history</div>
      </div>
    },
    // {
    //     name: <div className='display-1'>

    //         <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <g clip-path="url(#clip0_126_247)">
    //                 <path d="M3.05481 9.67157C3.05481 8.96419 3.62825 8.39075 4.33563 8.39075H6.89727C7.60465 8.39075 8.1781 7.8173 8.1781 7.10992C8.1781 6.40255 7.60465 5.8291 6.89727 5.8291H4.33563C2.21349 5.8291 0.493164 7.54943 0.493164 9.67157V12.2332C0.493164 12.9406 1.06661 13.514 1.77399 13.514C2.48136 13.514 3.05481 12.9406 3.05481 12.2332V9.67157Z" fill="#777E91" />
    //                 <path d="M28.6712 22.4798C28.6712 23.1872 28.0978 23.7606 27.3904 23.7606L24.8288 23.7606C24.1214 23.7606 23.548 24.3341 23.548 25.0414C23.548 25.7488 24.1214 26.3223 24.8288 26.3223L27.3904 26.3223C29.5126 26.3223 31.2329 24.6019 31.2329 22.4798V19.9181C31.2329 19.2108 30.6594 18.6373 29.9521 18.6373C29.2447 18.6373 28.6712 19.2108 28.6712 19.9181V22.4798Z" fill="#777E91" />
    //                 <path d="M3.05481 22.4798C3.05481 23.1872 3.62825 23.7606 4.33563 23.7606H6.89727C7.60465 23.7606 8.1781 24.3341 8.1781 25.0414C8.1781 25.7488 7.60465 26.3223 6.89727 26.3223H4.33563C2.21349 26.3223 0.493164 24.6019 0.493164 22.4798V19.9181C0.493164 19.2108 1.06661 18.6373 1.77399 18.6373C2.48136 18.6373 3.05481 19.2108 3.05481 19.9181L3.05481 22.4798Z" fill="#777E91" />
    //                 <path d="M27.3904 8.39075C28.0978 8.39075 28.6712 8.96419 28.6712 9.67157V12.2332C28.6712 12.9406 29.2447 13.514 29.9521 13.514C30.6594 13.514 31.2329 12.9406 31.2329 12.2332V9.67157C31.2329 7.54943 29.5126 5.8291 27.3904 5.8291H24.8288C24.1214 5.8291 23.548 6.40255 23.548 7.10992C23.548 7.8173 24.1214 8.39075 24.8288 8.39075L27.3904 8.39075Z" fill="#777E91" />
    //                 <path d="M6.89703 10.9521C6.18965 10.9521 5.61621 11.5256 5.61621 12.233V19.9179C5.61621 20.6253 6.18965 21.1987 6.89703 21.1987C7.60441 21.1987 8.17785 20.6253 8.17785 19.9179V12.233C8.17785 11.5256 7.60441 10.9521 6.89703 10.9521Z" fill="#777E91" />
    //                 <path d="M23.5477 10.9521C22.8403 10.9521 22.2669 11.5256 22.2669 12.233V19.9179C22.2669 20.6253 22.8403 21.1987 23.5477 21.1987H24.8285C25.5359 21.1987 26.1094 20.6253 26.1094 19.9179V12.233C26.1094 11.5256 25.5359 10.9521 24.8285 10.9521H23.5477Z" fill="#777E91" />
    //                 <path d="M10.7395 12.233C10.7395 11.5256 11.3129 10.9521 12.0203 10.9521H13.3011C14.0085 10.9521 14.582 11.5256 14.582 12.233V19.9179C14.582 20.6253 14.0085 21.1987 13.3011 21.1987H12.0203C11.3129 21.1987 10.7395 20.6253 10.7395 19.9179V12.233Z" fill="#777E91" />
    //                 <path d="M18.4244 10.9521C17.7171 10.9521 17.1436 11.5256 17.1436 12.233V19.9179C17.1436 20.6253 17.7171 21.1987 18.4244 21.1987C19.1318 21.1987 19.7053 20.6253 19.7053 19.9179V12.233C19.7053 11.5256 19.1318 10.9521 18.4244 10.9521Z" fill="#777E91" />
    //             </g>
    //             <defs>
    //                 <clipPath id="clip0_126_247">
    //                     <rect width="30.7397" height="30.7397" fill="white" transform="translate(0.493164 0.705566)" />
    //                 </clipPath>
    //             </defs>
    //         </svg>


    //         <div className="">2FA</div>
    //     </div>
    // },
    {
      name: <div className='display-1'>

        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8633 14.3971C13.593 14.3971 11.5706 14.4798 9.91824 14.5848C8.35094 14.6844 7.14579 15.8803 7.03331 17.4365C6.9533 18.5435 6.89758 19.71 6.89758 20.8012C6.89758 21.8924 6.9533 23.0589 7.03331 24.1659C7.14579 25.7221 8.35094 26.918 9.91824 27.0176C11.5706 27.1226 13.593 27.2053 15.8633 27.2053C18.1337 27.2053 20.1561 27.1226 21.8084 27.0176C23.3757 26.918 24.5809 25.7221 24.6934 24.1659C24.7734 23.0589 24.8291 21.8924 24.8291 20.8012C24.8291 19.71 24.7734 18.5435 24.6934 17.4365C24.5809 15.8803 23.3757 14.6844 21.8084 14.5848C20.1561 14.4798 18.1337 14.3971 15.8633 14.3971ZM9.75574 12.0283C6.9152 12.2089 4.68352 14.4129 4.47833 17.2518C4.39552 18.3976 4.33594 19.6292 4.33594 20.8012C4.33594 21.9732 4.39552 23.2048 4.47833 24.3506C4.68352 27.1895 6.9152 29.3935 9.75574 29.5741C11.4545 29.6821 13.5315 29.767 15.8633 29.767C18.1951 29.767 20.2722 29.6821 21.9709 29.5741C24.8115 29.3935 27.0432 27.1895 27.2483 24.3506C27.3312 23.2048 27.3907 21.9732 27.3907 20.8012C27.3907 19.6292 27.3312 18.3976 27.2483 17.2518C27.0432 14.4129 24.8115 12.2089 21.9709 12.0283C20.2722 11.9204 18.1951 11.8354 15.8633 11.8354C13.5315 11.8354 11.4545 11.9204 9.75574 12.0283Z" fill="#777E91" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1442 21.7391C17.9099 21.2962 18.425 20.4683 18.425 19.5201C18.425 18.1054 17.2782 16.9585 15.8634 16.9585C14.4486 16.9585 13.3018 18.1054 13.3018 19.5201C13.3018 20.4683 13.8169 21.2962 14.5826 21.7391V23.3626C14.5826 24.07 15.156 24.6434 15.8634 24.6434C16.5708 24.6434 17.1442 24.07 17.1442 23.3626V21.7391Z" fill="#777E91" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.45898 7.99298C9.45898 4.45608 12.3262 1.58887 15.8631 1.58887C19.4 1.58887 22.2672 4.45608 22.2672 7.99298V13.1163C22.2672 13.8236 21.6938 14.3971 20.9864 14.3971C20.279 14.3971 19.7056 13.8236 19.7056 13.1163V7.99298C19.7056 5.87084 17.9852 4.15051 15.8631 4.15051C13.741 4.15051 12.0206 5.87084 12.0206 7.99298V13.1163C12.0206 13.8236 11.4472 14.3971 10.7398 14.3971C10.0324 14.3971 9.45898 13.8236 9.45898 13.1163V7.99298Z" fill="#777E91" />
        </svg>



        <div className="">Change password</div>
      </div>
    },
  ])

  // const [rows, setRows] = useState([
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={stck} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         NEM
  //         <div className="min-xm">
  //           STX
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="105" height="34" viewBox="0 0 105 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1.59961 33H4.4523C4.92595 33 5.37525 32.7901 5.67926 32.4269L11.472 25.5058C11.5309 25.4354 11.5837 25.3601 11.6297 25.2806L15.7431 18.1773C16.2067 17.3766 17.2507 17.1349 18.019 17.6504L26.3036 23.2081C26.94 23.6351 27.7897 23.5508 28.3299 23.0072L31.2154 20.1036C31.7793 19.5362 32.6756 19.4724 33.3141 19.9544L36.313 22.2177C36.7417 22.5412 37.3042 22.6281 37.8105 22.449L43.0371 20.6L48.0071 18.2971C48.1462 18.2327 48.2753 18.1486 48.3904 18.0475L53.5467 13.5181C54.146 12.9916 55.0416 12.987 55.6462 13.5073L59.7773 17.0622C60.0325 17.2818 60.3505 17.4153 60.686 17.4437L66.1465 17.9057H72.8008C73.3037 17.9057 73.7774 17.6691 74.0797 17.2671L79.6132 9.90667C79.6665 9.83568 79.7257 9.76925 79.7901 9.70808L87.9128 1.99234C88.7775 1.17092 90.2046 1.55033 90.5473 2.69274L93.3669 12.0939C93.5345 12.6528 93.9931 13.0761 94.5636 13.1986L98.444 14.0316C99.1383 14.1806 99.6513 14.7695 99.7038 15.4777L100.49 26.0843C100.552 26.9198 101.248 27.566 102.085 27.566H103.6" stroke="#FF6838" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M4.4523 33H1.59961L101.993 33.3937C102.879 33.3972 103.6 32.6798 103.6 31.7937V29.1215C103.6 28.2625 102.903 27.566 102.044 27.566C101.23 27.566 100.553 26.9377 100.493 26.1255L99.7038 15.4777C99.6513 14.7695 99.1383 14.1806 98.444 14.0316L94.5636 13.1986C93.9931 13.0761 93.5345 12.6528 93.3669 12.0939L90.5472 2.69274C90.2046 1.55033 88.7775 1.17092 87.9128 1.99234L79.7901 9.70808C79.7257 9.76925 79.6665 9.83568 79.6132 9.90667L74.0797 17.2671C73.7774 17.6692 73.3037 17.9057 72.8008 17.9057H66.1465L60.686 17.4437C60.3505 17.4153 60.0325 17.2818 59.7773 17.0622L55.6462 13.5073C55.0416 12.987 54.146 12.9916 53.5467 13.5181L48.3904 18.0475C48.2753 18.1486 48.1462 18.2327 48.0071 18.2971L43.0371 20.6L37.8105 22.449C37.3042 22.6281 36.7417 22.5412 36.313 22.2177L33.3141 19.9544C32.6756 19.4724 31.7793 19.5362 31.2154 20.1036L28.3299 23.0072C27.7897 23.5508 26.94 23.6351 26.3036 23.2081L18.019 17.6504C17.2507 17.1349 16.2067 17.3766 15.7431 18.1773L11.6297 25.2806C11.5837 25.3601 11.5309 25.4354 11.472 25.5058L5.67926 32.4269C5.37525 32.7901 4.92595 33 4.4523 33Z" fill="url(#paint0_linear_17_24476)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24476" x1="53.1996" y1="-60.5623" x2="52.5456" y2="33.3996" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#EF466F" />
  //           <stop offset="1" stop-color="#EF466F" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={nem} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         NEM
  //         <div className="min-xm">
  //           STX
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#45B36B" />
  //           <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={btc} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         BitCoin
  //         <div className="min-xm">
  //           BTC
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#45B36B" />
  //           <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={stck} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         NEM
  //         <div className="min-xm">
  //           STX
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#45B36B" />
  //           <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={nem} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         NEM
  //         <div className="min-xm">
  //           STX
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="105" height="34" viewBox="0 0 105 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1.59961 33H4.4523C4.92595 33 5.37525 32.7901 5.67926 32.4269L11.472 25.5058C11.5309 25.4354 11.5837 25.3601 11.6297 25.2806L15.7431 18.1773C16.2067 17.3766 17.2507 17.1349 18.019 17.6504L26.3036 23.2081C26.94 23.6351 27.7897 23.5508 28.3299 23.0072L31.2154 20.1036C31.7793 19.5362 32.6756 19.4724 33.3141 19.9544L36.313 22.2177C36.7417 22.5412 37.3042 22.6281 37.8105 22.449L43.0371 20.6L48.0071 18.2971C48.1462 18.2327 48.2753 18.1486 48.3904 18.0475L53.5467 13.5181C54.146 12.9916 55.0416 12.987 55.6462 13.5073L59.7773 17.0622C60.0325 17.2818 60.3505 17.4153 60.686 17.4437L66.1465 17.9057H72.8008C73.3037 17.9057 73.7774 17.6691 74.0797 17.2671L79.6132 9.90667C79.6665 9.83568 79.7257 9.76925 79.7901 9.70808L87.9128 1.99234C88.7775 1.17092 90.2046 1.55033 90.5473 2.69274L93.3669 12.0939C93.5345 12.6528 93.9931 13.0761 94.5636 13.1986L98.444 14.0316C99.1383 14.1806 99.6513 14.7695 99.7038 15.4777L100.49 26.0843C100.552 26.9198 101.248 27.566 102.085 27.566H103.6" stroke="#FF6838" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M4.4523 33H1.59961L101.993 33.3937C102.879 33.3972 103.6 32.6798 103.6 31.7937V29.1215C103.6 28.2625 102.903 27.566 102.044 27.566C101.23 27.566 100.553 26.9377 100.493 26.1255L99.7038 15.4777C99.6513 14.7695 99.1383 14.1806 98.444 14.0316L94.5636 13.1986C93.9931 13.0761 93.5345 12.6528 93.3669 12.0939L90.5472 2.69274C90.2046 1.55033 88.7775 1.17092 87.9128 1.99234L79.7901 9.70808C79.7257 9.76925 79.6665 9.83568 79.6132 9.90667L74.0797 17.2671C73.7774 17.6692 73.3037 17.9057 72.8008 17.9057H66.1465L60.686 17.4437C60.3505 17.4153 60.0325 17.2818 59.7773 17.0622L55.6462 13.5073C55.0416 12.987 54.146 12.9916 53.5467 13.5181L48.3904 18.0475C48.2753 18.1486 48.1462 18.2327 48.0071 18.2971L43.0371 20.6L37.8105 22.449C37.3042 22.6281 36.7417 22.5412 36.313 22.2177L33.3141 19.9544C32.6756 19.4724 31.7793 19.5362 31.2154 20.1036L28.3299 23.0072C27.7897 23.5508 26.94 23.6351 26.3036 23.2081L18.019 17.6504C17.2507 17.1349 16.2067 17.3766 15.7431 18.1773L11.6297 25.2806C11.5837 25.3601 11.5309 25.4354 11.472 25.5058L5.67926 32.4269C5.37525 32.7901 4.92595 33 4.4523 33Z" fill="url(#paint0_linear_17_24476)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24476" x1="53.1996" y1="-60.5623" x2="52.5456" y2="33.3996" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#EF466F" />
  //           <stop offset="1" stop-color="#EF466F" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={btc} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         BitCoin
  //         <div className="min-xm">
  //           BTC
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="105" height="34" viewBox="0 0 105 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1.59961 33H4.4523C4.92595 33 5.37525 32.7901 5.67926 32.4269L11.472 25.5058C11.5309 25.4354 11.5837 25.3601 11.6297 25.2806L15.7431 18.1773C16.2067 17.3766 17.2507 17.1349 18.019 17.6504L26.3036 23.2081C26.94 23.6351 27.7897 23.5508 28.3299 23.0072L31.2154 20.1036C31.7793 19.5362 32.6756 19.4724 33.3141 19.9544L36.313 22.2177C36.7417 22.5412 37.3042 22.6281 37.8105 22.449L43.0371 20.6L48.0071 18.2971C48.1462 18.2327 48.2753 18.1486 48.3904 18.0475L53.5467 13.5181C54.146 12.9916 55.0416 12.987 55.6462 13.5073L59.7773 17.0622C60.0325 17.2818 60.3505 17.4153 60.686 17.4437L66.1465 17.9057H72.8008C73.3037 17.9057 73.7774 17.6691 74.0797 17.2671L79.6132 9.90667C79.6665 9.83568 79.7257 9.76925 79.7901 9.70808L87.9128 1.99234C88.7775 1.17092 90.2046 1.55033 90.5473 2.69274L93.3669 12.0939C93.5345 12.6528 93.9931 13.0761 94.5636 13.1986L98.444 14.0316C99.1383 14.1806 99.6513 14.7695 99.7038 15.4777L100.49 26.0843C100.552 26.9198 101.248 27.566 102.085 27.566H103.6" stroke="#FF6838" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M4.4523 33H1.59961L101.993 33.3937C102.879 33.3972 103.6 32.6798 103.6 31.7937V29.1215C103.6 28.2625 102.903 27.566 102.044 27.566C101.23 27.566 100.553 26.9377 100.493 26.1255L99.7038 15.4777C99.6513 14.7695 99.1383 14.1806 98.444 14.0316L94.5636 13.1986C93.9931 13.0761 93.5345 12.6528 93.3669 12.0939L90.5472 2.69274C90.2046 1.55033 88.7775 1.17092 87.9128 1.99234L79.7901 9.70808C79.7257 9.76925 79.6665 9.83568 79.6132 9.90667L74.0797 17.2671C73.7774 17.6692 73.3037 17.9057 72.8008 17.9057H66.1465L60.686 17.4437C60.3505 17.4153 60.0325 17.2818 59.7773 17.0622L55.6462 13.5073C55.0416 12.987 54.146 12.9916 53.5467 13.5181L48.3904 18.0475C48.2753 18.1486 48.1462 18.2327 48.0071 18.2971L43.0371 20.6L37.8105 22.449C37.3042 22.6281 36.7417 22.5412 36.313 22.2177L33.3141 19.9544C32.6756 19.4724 31.7793 19.5362 31.2154 20.1036L28.3299 23.0072C27.7897 23.5508 26.94 23.6351 26.3036 23.2081L18.019 17.6504C17.2507 17.1349 16.2067 17.3766 15.7431 18.1773L11.6297 25.2806C11.5837 25.3601 11.5309 25.4354 11.472 25.5058L5.67926 32.4269C5.37525 32.7901 4.92595 33 4.4523 33Z" fill="url(#paint0_linear_17_24476)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24476" x1="53.1996" y1="-60.5623" x2="52.5456" y2="33.3996" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#EF466F" />
  //           <stop offset="1" stop-color="#EF466F" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={stck} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         NEM
  //         <div className="min-xm">
  //           STX
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#45B36B" />
  //           <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={nem} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         NEM
  //         <div className="min-xm">
  //           STX
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#45B36B" />
  //           <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  //   {
  //     name: <div className='display-1'>
  //       <img width="40" height="40" src={btc} alt="external-Stacks-cryptocurrency-black-fill-lafs" />
  //       <div className="xm">
  //         BitCoin
  //         <div className="min-xm">
  //           BTC
  //         </div>
  //       </div>
  //     </div>,
  //     price: '€0.43463',
  //     change: '+ 7.96%',
  //     chart: <svg width="104" height="34" viewBox="0 0 104 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path d="M1 33H3.85269C4.32634 33 4.77564 32.7901 5.07965 32.4269L10.8724 25.5058C10.9313 25.4354 10.9841 25.3601 11.0301 25.2806L15.1435 18.1773C15.6071 17.3766 16.6511 17.1349 17.4194 17.6504L25.704 23.2081C26.3404 23.6351 27.1901 23.5508 27.7303 23.0072L30.6158 20.1036C31.1797 19.5362 32.076 19.4724 32.7145 19.9544L35.7134 22.2177C36.1421 22.5412 36.7046 22.6281 37.2109 22.449L42.4375 20.6L47.4075 18.2971C47.5466 18.2327 47.6757 18.1486 47.7908 18.0475L52.9471 13.5181C53.5464 12.9916 54.442 12.987 55.0466 13.5073L59.1777 17.0622C59.4329 17.2818 59.7509 17.4153 60.0864 17.4437L65.5469 17.9057H72.2011C72.7041 17.9057 73.1778 17.6691 73.48 17.2671L79.0136 9.90667C79.0669 9.83568 79.1261 9.76925 79.1905 9.70808L87.3131 1.99234C88.1779 1.17092 89.605 1.55033 89.9476 2.69274L92.7673 12.0939C92.9349 12.6528 93.3935 13.0761 93.964 13.1986L97.8444 14.0316C98.5387 14.1806 99.0517 14.7695 99.1042 15.4777L99.8902 26.0843C99.9521 26.9198 100.648 27.566 101.486 27.566H103" stroke="#58BD7D" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
  //       <path d="M3.85269 33H1L101.394 33.3937C102.28 33.3972 103 32.6798 103 31.7937V29.1215C103 28.2625 102.304 27.566 101.444 27.566C100.63 27.566 99.9534 26.9377 99.8932 26.1255L99.1042 15.4777C99.0517 14.7695 98.5387 14.1806 97.8444 14.0316L93.964 13.1986C93.3935 13.0761 92.9349 12.6528 92.7673 12.0939L89.9476 2.69274C89.605 1.55033 88.1779 1.17092 87.3131 1.99234L79.1905 9.70808C79.1261 9.76925 79.0669 9.83568 79.0135 9.90667L73.48 17.2671C73.1778 17.6692 72.7041 17.9057 72.2011 17.9057H65.5469L60.0864 17.4437C59.7509 17.4153 59.4329 17.2818 59.1777 17.0622L55.0466 13.5073C54.442 12.987 53.5464 12.9916 52.9471 13.5181L47.7908 18.0475C47.6757 18.1486 47.5466 18.2327 47.4075 18.2971L42.4375 20.6L37.2109 22.449C36.7046 22.6281 36.1421 22.5412 35.7134 22.2177L32.7145 19.9544C32.076 19.4724 31.1797 19.5362 30.6158 20.1036L27.7303 23.0072C27.1901 23.5508 26.3404 23.6351 25.704 23.2081L17.4194 17.6504C16.6511 17.1349 15.6071 17.3766 15.1435 18.1773L11.0301 25.2806C10.9841 25.3601 10.9313 25.4354 10.8724 25.5058L5.07965 32.4269C4.77564 32.7901 4.32634 33 3.85269 33Z" fill="url(#paint0_linear_17_24454)" />
  //       <defs>
  //         <linearGradient id="paint0_linear_17_24454" x1="52.6" y1="-59.8302" x2="51.9617" y2="32.9997" gradientUnits="userSpaceOnUse">
  //           <stop stop-color="#45B36B" />
  //           <stop offset="1" stop-color="#45B36B" stop-opacity="0" />
  //         </linearGradient>
  //       </defs>
  //     </svg>,
  //     volume: '6,624,494.71'
  //   },
  // ])

  const [open, setOpen] = React.useState(false);
  const handleOpen = async (e) => {
    if (isChecked === true) {
      setOpen(true)
    } else {
      const test = await generate2FA()
      if (test) {
        setCopy(false)
        setOpen(true);
        e.stopPropagation();
      }
    }
  }
  const handleClose = (e) => { setOpen(false) }

  const [isChecked, setIsChecked] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [load, setLoad] = useState(false)
  const [load1, setLoad1] = useState(false)

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };


  const tabs = (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: 'divider' }}
      className='tabs-prf'
    >
      {prfContent.map((row, i) => {
        return (
          <Tab label={row.name} {...a11yProps(i)} />
        )
      })}


    </Tabs>
  )




  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    if (file) {
      // setForm(file)
      uploadImage(file)
    }
  };

  const uploadImage = async (imgForm) => {
    try {
      const formdata = new FormData();
      formdata.append("image", imgForm)
      const { data } = await Axios.post('/uploadimage', formdata)
      if (data?.success) {
        setUserData({ ...userData, image: data?.result })
      }
    } catch (error) {
      console.log("🚀 ~ uploadImage ~ error:", error)
    }
  }

  const updateProfile = async () => {
    try {
      if (userData?.name === '') {
        setNameErr('Please Enter Name')
      }
      else {
        setLoad(true)
        let payload = {
          name: userData?.name,
          image: userData?.image
        }
        const { data } = await Axios.patch('/profile', payload, {
          headers: {
            Authorization: window.localStorage.getItem('Rikosta')
          }
        })
        if (data.success) {
          setLoad(false)
          toast.success(data.message)

        }
        else {
          setLoad(false)
          toast.error(data.message)
        }
      }
    } catch (error) {
      setLoad(false)
      console.log('🚀 ~ updateProfile ~ error', error);
    }
  }

  const changePassword = async () => {
    try {
      if (oldpassword === '') {

        setOldpasswordErr("Please Enter Old Password")
      }
      else if (newPassword === '') {

        setNewPasswordErr('Please Enter New Password')
      }
      else if (confirmPassword === '') {

        setConfirmPasswordErr('Please Enter Confirm Password')
      }
      else if (newPassword !== confirmPassword) {
        setConfirmPasswordErr("Password Mismatching")
      }
      else {

        setLoad1(true)
        const payload = {
          oldPassword: oldpassword,
          newPassword: newPassword
        }
        const { data } = await Axios.post('/profile/changePassword', payload,
          {
            headers: {
              Authorization: localStorage.getItem('Rikosta')
            }
          }
        )
        // console.log(data, 'data');

        if (data.msg) {
          setLoad1(false)
          toast.success(data.msg)
          setOldpassword('')
          setNewPassword('')
          setConfirmPassword('')
        }
        else {
          setLoad1(false)
          toast.error(data.message)
        }
      }
    } catch (error) {
      console.log(error, 'err');
      setLoad1(false)
      toast.error(error?.response?.data?.message)
    }
  }

  const generate2FA = async () => {
    try {
      const { data } = await Axios.post('/generate2fa', {}, {
        headers: {
          Authorization: window.localStorage.getItem('Rikosta')
        }
      })

      if (data?.success === true) {
        setSecret(data?.result?.secret)
        const auth8url = data?.result?.otpauth_url + "&issuer=Clicks&Trades"
        setQrCode(auth8url)
        return true
      }
    } catch (error) {
      console.log("🚀 ~ generate2FA ~ error:", error)
      return false
    }
  }

  const verify2FA = async () => {
    try {
      if (secret1 === "") {
        setSecret1Err("OTP is required")
      } else {
        const { data } = await Axios.post('/verifyTwoFactorAuth', { secret: secret1 }, {
          headers: {
            Authorization: window.localStorage.getItem('Rikosta')
          }
        })
        if (data?.success === true) {
          toast.success("2FA Activated Successfully")
          setOpen(false)
          setSecret1("");
          setSecret1Err("");
          get2faStatus();
        }
        else {
          toast.error(data.message)
        }
      }
    } catch (error) {
      toast.error(error.response.data.message)
      setSecret1('')
      console.log("🚀 ~ verify2FA ~ error:", error)
    }
  }

  const disable2fa = async () => {
    try {
      const { data } = await Axios.post('/disable2FA', {}, {
        headers: {
          Authorization: window.localStorage.getItem('Rikosta')
        }
      })
      if (data?.success === true) {
        setOpen(false)
        setIsChecked(!isChecked)
      }
    } catch (error) {
      console.log("🚀 ~ disable2fa ~ error:", error)
    }
  }

  const get2faStatus = async () => {
    try {
      const { data } = await Axios.get('/checkTwoFactorAuth', {
        headers: {
          Authorization: window.localStorage.getItem('Rikosta')
        }
      })
      if (data?.result?.status === true) {
        setIsChecked(data?.result?.status)
      } else {
        setIsChecked(false)
      }
    } catch (error) {
      console.log("🚀 ~ get2faStatus ~ error:", error)
    }
  }

  useEffect(() => {
    get2faStatus()
  }, [])




  const panel = (
    <>
      <TabPanel value={value} index={0}>
        {!mdScreen && <div className="">
          <ArrowBackIcon onClick={handleChange} sx={{ fill: '#fff' }} />
        </div>}
        <div className='tabcontentbg'>
          <div className="display-2 mob-flex-r">
            <div className="prfname">
              {/* Breanne Schinner */} {userData?.name}
              <div className="name-email">
                {/* schinner@u.net */} {userData?.email}
              </div>
              <div className="verify">
                <Button variant="contained">
                  {userData?.verified ? "verified" : "not verified"}
                </Button>
              </div>
            </div>
            <Button
              component="label"
              role={undefined}
              // variant="contained"
              tabIndex={-1}
            // startIcon={<CloudUploadIcon />}
            >
              <div className="prf-img">
                <img src={userData?.image ? userData?.image : profimg} alt="profimg" />
                <CloudUploadIcon className='cloud-icon' />
              </div>
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => handleImageUpload(e)}
                multiple
              />
            </Button>

          </div>
          <div className="display-2 mob-flex" style={{ marginTop: 'var(--mar-15px)' }}>
            <div className="prfname">
              Profile
            </div>

            {/* <ReactFlagsSelect
              selected={selected}
              onSelect={(code) => setSelected(code)}
              className='react-flag-select'
            /> */}
          </div>

          <div className="display-1 mob-flex" style={{ marginTop: 'var(--mar-15px)' }}>

            <TextField id="outlined-basic" placeholder='Name' variant="outlined" className='prf-name' value={userData?.name}
              onChange={(e) => { setUserData({ ...userData, name: e.target.value }); setNameErr('') }} />
            {nameErr && <p style={{ color: 'red' }}>{nameErr}</p>}

            {/* <TextField id="outlined-basic" placeholder='Last name' variant="outlined" className='prf-name' /> */}

          </div>

          <div className="display-1 mob-flex" style={{ marginTop: 'var(--mar-25px)' }}>

            <TextField id="outlined-basic" placeholder='Email' variant="outlined" className='prf-name' value={userData.email} />

            {/* <TextField id="outlined-basic" placeholder='Phone Number' variant="outlined" className='prf-name' /> */}

          </div>

          {/* <div className="display-2" style={{ marginTop: 'var(--mar-25px)' }}> */}
          <hr style={{ marginTop: 'var(--mar-25px)' }} className='prf-hr' />
          <div className="display-2" style={{ marginTop: 'var(--mar-25px)' }}>
            <div className="pushnotify">
              Push Notification
            </div>

            <div className="radio-chk">
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
            </div>
          </div>
          <div className="display-2" style={{ marginTop: 'var(--mar-25px)' }}>
            <div className="pushnotify">
              2FA Enable
            </div>

            <div className="radio-chk">
              <AntSwitch inputProps={{ 'aria-label': 'ant design' }}
                onClick={(e) => { handleOpen(e) }}
                checked={isChecked}

              />
            </div>
          </div>

          <div className="display-4 saveset" style={{ marginTop: 'var(--mar-25px)' }}>
            {
              load ? <Button >
                Processing...
              </Button> :
                <Button onClick={() => { updateProfile() }}>
                  Save settings
                </Button>
            }

          </div>
        </div>

        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="text-right">
              <CancelOutlinedIcon sx={{ fill: 'var(--clr-font1)', cursor: 'pointer' }} onClick={handleClose} />
            </div>
            <Typography id="modal-2fa" variant="h3" component="h2">
              2FA <span>{isChecked ? "Disabled" : "Enabled"}</span>
            </Typography>
            {
              !isChecked ?
                <>
                  <div className="text-center qr-prf" style={{ marginTop: 'var(--mar-25px)' }}>
                    {/* <img width="100" height="100" src="https://img.icons8.com/officel/100/qr-code.png" alt="qr-code" /> */}
                    <QRCodeSVG value={qrCode}
                      includeMargin={true} />
                    {/* <QRCodeSVG value="https://google.com  " size={256} bgColor="#FFFFFF" fgColor="#000000" /> */}
                    {/* <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 256 }}
                value={qrCode}
            /> */}
                    {/* {console.log(qrCode, 'qrcode')} */}
                  </div>
                  <div className="text-center">
                    {secret?.length > 20 ? secret?.slice(0, 20) + "..." : secret}
                    <Tooltip title={!copy ? 'copy' : 'copied'} placement="right">
                      <ContentCopyIcon style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(secret);
                          setCopy(true)
                        }} />
                    </Tooltip>
                  </div>
                  <div className="text-center" style={{ marginTop: 'var(--mar-25px)' }}>
                    <TextField id="outlined-basic" placeholder='OTP' variant="outlined" className='prf-name' value={secret1} onChange={(e) => { setSecret1(e.target.value); setSecret1Err('') }} />
                    {secret1Err && <p style={{ color: 'red' }}>{secret1Err}</p>}
                  </div>
                </> : <></>
            }
            <div className="text-center saveset" style={{ marginTop: 'var(--mar-25px)' }}>
              {
                isChecked === false ?
                  <Button onClick={() => { verify2FA() }}>
                    Enable
                  </Button> :
                  <Button onClick={() => { disable2fa() }}>
                    Disabled
                  </Button>
              }

            </div>
          </Box>
        </Modal>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {!mdScreen && <div className="">
          <ArrowBackIcon onClick={handleChange} sx={{ fill: '#fff' }} />
        </div>}
        <Subscription />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!mdScreen && <div className="">
          <ArrowBackIcon onClick={handleChange} sx={{ fill: '#fff' }} />
        </div>}
        <div >
          <div className="market-table">
            <TableContainer className='marktab'>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="center">Login Time</TableCell>
                    <TableCell align="center">LogOut Time</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Date</TableCell>
                    {/* <TableCell align="center"></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessionData.map((row, i) => (

                    <TableRow
                      key={row?.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">
                        {row?.name}
                      </TableCell>
                      <TableCell align="center">{moment(row?.login_time).format("hh:mm A")}</TableCell>
                      {/* {row?.logout_time !== null ? <TableCell align="center" sx={{ color: i % 2 === 0 ? '#03A692 !important' : '#E62C50 !important' }}>{moment(row?.logout_time).format("hh:mm A")}</TableCell> : <></>} */}
                      <TableCell align="center" > {row?.logout_time > 0 ? `${moment(row?.logout_time).format("hh:mm A")}` : '-'}</TableCell>
                      <TableCell align="center">{row?.duration}</TableCell>
                      <TableCell align="center" >{moment(row?.createAt).format("DD/MM/YYYY")}</TableCell>
                      {/* <TableCell align="center">
                          <Button>
                            Buy
                          </Button>
                        </TableCell> */}
                    </TableRow>

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        {!mdScreen && <div className="">
          <ArrowBackIcon onClick={handleChange} sx={{ fill: '#fff' }} />
        </div>}
        <div className='tabcontentbg'>

          <div className="text-center nrwpswd">
            New password
          </div>
          <div className="display-3" style={{ marginTop: 'var(--mar-15px)' }}>

            <div className="prf-name2">
              <div className="text-left chgpwsd-email">
                Old Password
              </div>
              <TextField id="outlined-basic"
                placeholder='Old Password'
                variant="outlined"
                className=''
                value={oldpassword}
                onChange={(e) => { setOldpassword(e.target.value); setOldpasswordErr('') }}
              />
            </div>
            {oldpasswordErr && <p style={{ color: "red" }}>{oldpasswordErr}</p>}
          </div>

          <div className="display-3" style={{ marginTop: 'var(--mar-15px)' }}>
            <div className="prf-name2">
              <div className="text-left chgpwsd-email">
                New Password
              </div>
              <FormControl sx={{ m: 1, }} variant="outlined">
                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >

                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // label="Password"
                  placeholder='New Password'
                  value={newPassword}
                  onChange={(e) => { setNewPassword(e.target.value); checkpass(e.target.value) }}

                // className=''
                />
              </FormControl>
              {/* <TextField id="outlined-basic" placeholder='Email' variant="outlined"  /> */}
            </div>

          </div>
          {newPasswordErr && <p style={{ color: "red" }}>{newPasswordErr}</p>}
          <div className="display-3" style={{ marginTop: 'var(--mar-15px)' }}>
            <div className="prf-name2">
              <div className="text-left chgpwsd-email">
                Confirm Password
              </div>
              <FormControl sx={{ m: 1, }} variant="outlined">
                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >

                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // label="Password"
                  placeholder='Confirm Password'
                  onChange={(e) => { setConfirmPassword(e.target.value); setConfirmPasswordErr('') }}
                  value={confirmPassword}
                // className=''
                />
              </FormControl>
              {/* <TextField id="outlined-basic" placeholder='Email' variant="outlined"  /> */}
            </div>

          </div>
          {confirmPasswordErr && <p style={{ color: "red" }}>{confirmPasswordErr}</p>}
          {/* <div className="display-2" style={{ marginTop: 'var(--mar-25px)' }}> */}
          {/* <hr style={{ marginTop: 'var(--mar-25px)' }} className='prf-hr' /> */}


          <div className="display-3 saveset x2" style={{ marginTop: 'var(--mar-25px)' }}>
            {
              load1 ? <Button>
                Processing...
              </Button> :
                <Button onClick={() => { changePassword() }}>
                  Change password
                </Button>
            }
          </div>
        </div>
      </TabPanel>
    </>
  )
  return (
    <div className='profile'>
      <Header />
      <Grid2 container spacing={2} sx={{ margin: '100px auto' }} size={{ xs: 12, md: 11, lg: 11 }} justifyContent={'center'}>
        {/* <Grid2 item size={12}> */}
        {/* </Grid2> */}
        {/* <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 10, xl: 10 }}> */}
        <Grid2 container size={{ xs: 12, md: 12, lg: 12 }}>

          <Grid2 item size={{ xs: 12, sm: 12, md: 4, lg: 3, xl: 2 }}>
            {mobTab ? panel : tabs}
          </Grid2>
          {mdScreen && <Grid2 item size={{ xs: 12, sm: 12, md: 8, lg: 9, xl: 10 }}>
            {panel}
          </Grid2>}

        </Grid2>
        {/* </Grid2> */}
      </Grid2>
      <Toaster />

    </div>
  )
}

export default Profile