import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import '../Login/Login.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "../../../Images/logo.webp";
import { useNavigate, NavLink } from 'react-router-dom';
import Axios from '../../Axios';
// import toast from "react-hot-toast";
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import toast, { Toaster } from 'react-hot-toast';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const Register = () => {

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
    // const regex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // var token = window.localStorage.getItem('Sankar')


    const navigate = useNavigate()

    // useEffect(() => {
    //     if (token) {
    //         navigate(`${consts.mainUrl}/dashboard`)
    //     }
    // }, [token])
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [cnfPassword, setCnfPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [cnfPasswordError, setCnfPasswordError] = useState("")
    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)

    const [chkBox, setChkBox] = useState(false)
    const [chkBox2, setChkBox2] = useState(false)

    const [nameErroe, setNameErroe] = useState("")

    console.log(chkBox, chkBox2, 'chkBox2');


    const [status, setStatus] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    console.log(name, cnfPassword, password, email, "inputs")

    const checkemail = (e) => {
        if (!regex.test(e)) {
            setEmailError("Invalid Email Format")
        } else {
            setEmailError("")
        }
    }

    const checkpass = (e) => {
        console.log(e, "vijay");
        if (!passwordRegex.test(e)) {
            setPasswordError("Password Must Contain MIN 8 Character , One ALPHABET And Must Contain One Number ")
        } else {
            setPasswordError("")
        }

    }


    const checkconfirmpass = (e) => {
        if (password != e) {
            setCnfPasswordError("Password Mismatching")
        } else {
            setCnfPasswordError("")
        }

    }

    const checkbox1 = () => {
        setCheck1(!check1)
    }

    const checkbox2 = () => {
        setCheck2(!check2)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name === "") {
            // toast.error("Please Enter Password");
            setNameErroe("Please Enter Name");
            return;
        }
        if (email === "") {
            toast.error("Please Enter Email");
            setEmailError("Please Enter Email");
            return;
        }
        if (password === "") {
            toast.error("Please Enter Password");
            setPasswordError("Please Enter Password");
            return;
        }
        if (cnfPassword === "") {
            // toast.error("Please Enter Confirm Password");
            setCnfPasswordError("Please Enter Confirm Password");
            return;
        }
        if (check1 === false) {
            toast.error("Please Accept Terms And Condition");
            return;
        }
        if (check2 === false) {
            toast.error("Please Accept Pre-Flight Checklist");
            return;
        }
        try {
            setStatus(true);
            // First check if the email already exists
            var payload = {
                name: name,
                email: email,
                password: password,
            }
            const { data } = await Axios.post('/register', payload);
            if (data?.success === true) {
                toast.success("Registered Successfully");
                window.localStorage.setItem("email", email)
                handleOpen()
                setName("")
                setEmail("")
                setCnfPassword("")
                setPassword("")
                setEmailError("")
                setNameErroe('')
                setPasswordError("")
                setCnfPasswordError("")
            } else {
                toast.error("Failed to submit data");
            }
            setStatus(false);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.error(error);
            setStatus(false);
        }
    };



    return (
        <div className='auth-page' style={{ height: '100vh', overflow: 'hidden' }}>

            <Grid container spacing={0} style={{ height: '100%' }} alignItems={'center'}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className="login-left-part">
                        <div className="logo-part">
                            <Link to='/'>
                                <img src={Logo} alt="click&trade" style={{ width: '250px' }} className='log-logo' />
                            </Link>
                        </div>

                        <div className='login-grid'>
                            <div className="auth-form">

                                <div className='email-filed'>
                                    {/* <label className='display-1 fllname'>Name</label> */}
                                    <TextField placeholder='Name'
                                        value={name}
                                        onChange={(e) => { setName(e.target.value); setNameErroe("") }}

                                        slotProps={{
                                            input: {
                                                startAdornment: <InputAdornment position="start">
                                                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.28261 8.94116C5.06386 8.94116 3.28261 7.15991 3.28261 4.94116C3.28261 2.75366 5.06386 0.941162 7.28261 0.941162C9.47011 0.941162 11.2826 2.75366 11.2826 4.94116C11.2826 7.15991 9.47011 8.94116 7.28261 8.94116ZM10.0639 9.94116C12.3764 9.94116 14.2826 11.8474 14.2826 14.1599V15.4412C14.2826 16.2849 13.5951 16.9412 12.7826 16.9412H1.78261C0.938864 16.9412 0.282614 16.2849 0.282614 15.4412V14.1599C0.282614 11.8474 2.15761 9.94116 4.47011 9.94116H5.00136C5.68886 10.2849 6.47011 10.4412 7.28261 10.4412C8.09511 10.4412 8.84511 10.2849 9.53261 9.94116H10.0639Z" fill="#15BD6F" />
                                                    </svg>
                                                </InputAdornment>,
                                            },
                                        }}

                                    />
                                    <div className="err-div mt-10">{nameErroe}</div>
                                </div>


                                <div className='email-filed'>
                                    {/* <label className='display-1 fllname'>Email</label> */}
                                    <TextField placeholder='Enter email address'
                                        onChange={(e) => { checkemail(e.target.value); setEmail(e.target.value); }}
                                        value={email}
                                        slotProps={{
                                            input: {
                                                startAdornment: <InputAdornment position="start">
                                                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.28261 8.94116C5.06386 8.94116 3.28261 7.15991 3.28261 4.94116C3.28261 2.75366 5.06386 0.941162 7.28261 0.941162C9.47011 0.941162 11.2826 2.75366 11.2826 4.94116C11.2826 7.15991 9.47011 8.94116 7.28261 8.94116ZM10.0639 9.94116C12.3764 9.94116 14.2826 11.8474 14.2826 14.1599V15.4412C14.2826 16.2849 13.5951 16.9412 12.7826 16.9412H1.78261C0.938864 16.9412 0.282614 16.2849 0.282614 15.4412V14.1599C0.282614 11.8474 2.15761 9.94116 4.47011 9.94116H5.00136C5.68886 10.2849 6.47011 10.4412 7.28261 10.4412C8.09511 10.4412 8.84511 10.2849 9.53261 9.94116H10.0639Z" fill="#15BD6F" />
                                                    </svg>
                                                </InputAdornment>,
                                            },
                                        }}

                                    />
                                    <div className="err-div mt-10">{emailError ? <p style={{ color: "red" }}>{emailError}</p> : ""}</div>
                                </div>

                                <div className='email-filed phr'>
                                    {/* <label className='display-1 fllname'>Enter a password </label> */}
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            value={password}
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter a password'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {!showPassword ? <VisibilityOff sx={{ fill: '#fff' }} /> : <Visibility sx={{ fill: '#fff' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="start"
                                                    >
                                                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.6014 7.01721C13.4139 7.01721 14.1014 7.70471 14.1014 8.51721V14.5172C14.1014 15.361 13.4139 16.0172 12.6014 16.0172H1.60138C0.757629 16.0172 0.101379 15.361 0.101379 14.5172V8.51721C0.101379 7.70471 0.757629 7.01721 1.60138 7.01721H2.35138V4.76721C2.35138 2.17346 4.47638 0.0172119 7.10138 0.0172119C9.69513 0.0172119 11.8514 2.17346 11.8514 4.76721V7.01721H12.6014ZM9.35138 7.01721V4.76721C9.35138 3.54846 8.32013 2.51721 7.10138 2.51721C5.85138 2.51721 4.85138 3.54846 4.85138 4.76721V7.01721H9.35138Z" fill="#15BD6F" />
                                                        </svg>

                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(e) => { setPassword(e.target.value); checkpass(e.target.value) }}

                                        />
                                    </FormControl>
                                    {/* <div className='forget-link fllname mt-10'><Link to=''>Forgot Password?</Link></div> */}
                                    <div className="err-div mt-10">{passwordError}</div>
                                </div>

                                <div className='email-filed phr'>
                                    {/* <label className='display-1 fllname'>Re-enter your password</label> */}
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            value={cnfPassword}
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Re-enter your password'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {!showPassword ? <VisibilityOff sx={{ fill: '#fff' }} /> : <Visibility sx={{ fill: '#fff' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="start"
                                                    >
                                                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.6014 7.01721C13.4139 7.01721 14.1014 7.70471 14.1014 8.51721V14.5172C14.1014 15.361 13.4139 16.0172 12.6014 16.0172H1.60138C0.757629 16.0172 0.101379 15.361 0.101379 14.5172V8.51721C0.101379 7.70471 0.757629 7.01721 1.60138 7.01721H2.35138V4.76721C2.35138 2.17346 4.47638 0.0172119 7.10138 0.0172119C9.69513 0.0172119 11.8514 2.17346 11.8514 4.76721V7.01721H12.6014ZM9.35138 7.01721V4.76721C9.35138 3.54846 8.32013 2.51721 7.10138 2.51721C5.85138 2.51721 4.85138 3.54846 4.85138 4.76721V7.01721H9.35138Z" fill="#15BD6F" />
                                                        </svg>

                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(e) => { setCnfPassword(e.target.value); checkconfirmpass(e.target.value) }}

                                        />
                                    </FormControl>
                                    {/* <div className='forget-link fllname mt-10'><Link to=''>Forgot Password?</Link></div> */}
                                    <div className="err-div mt-10">{cnfPasswordError}</div>
                                </div>
                                <div className="mt-10 display-1">
                                    <Checkbox {...label} checked={check1} onClick={checkbox1} className='chk-com' />

                                    <div className="chck-label">
                                        I agree to the <NavLink to='/terms'>Terms of Use</NavLink>and<NavLink to='/policy'>Privacy Policy</NavLink>
                                    </div>
                                </div>
                                <div className="mt-10 display-1">
                                    <Checkbox {...label} checked={check2} onClick={checkbox2} className='chk-com' />

                                    <div className="chck-label">
                                        I agree to the <span>Pre-Flight Checklist</span>
                                    </div>
                                </div>
                                <div className='form-submit mt-20'>
                                    <Button onClick={handleSubmit} > create Free Account</Button>
                                </div>

                                <div className="register-acctn">
                                    <p className='reg-free'>Alreadyhave an account?  <NavLink className='reg-free' to='/login'>Log-in</NavLink></p>
                                </div>

                                <div className="register-acctn">
                                    <p className='reg-free'>Dont't recieve mail?   <NavLink className='reg-free' to='/resend'>Resend Verification mail?</NavLink></p>
                                </div>
                            </div>

                        </div>

                    </div>
                </Grid>

                {mdScreen && <Grid item xs={12} sm={12} md={6} lg={8} xl={8}></Grid>}

            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="login-left-part">
                        <div className="logo-part">
                            <Link to='/'>
                                <img src={Logo} alt="click&trade" style={{ width: '250px' }} className='log-logo' />
                            </Link>
                        </div>

                        <div className='login-grid'>
                            <div className="">
                                <div className="twofa-title text-center">
                                    Welcome!!
                                </div>

                                <div className="register-acctn">
                                    <p className='reg-free'>Thank you for registering with Clicks and Trades </p>
                                </div>





                                <div className='form-submit margin-t-30px'>
                                    <Button onClick={() => { navigate(`/verify`) }} >Thank You</Button>
                                </div>


                            </div>

                        </div>

                    </div>
                </Box>
            </Modal>
            <Toaster />
        </div >
    )
}

export default Register
