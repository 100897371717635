import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import './Login/Login.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "../../Images/logo.webp";
import consts from '../../constant.js';
import { useNavigate, NavLink } from 'react-router-dom';
import Axios from './../Axios.js';
// import toast from "react-hot-toast";
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import toast, { Toaster } from 'react-hot-toast';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const ResetPaswd = () => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

    // const [showPasswordotp, setShowPasswordotp] = useState(false);
    // const handleClickShowPasswordotp = () => setShowPasswordotp((show) => !show)



    const navigate = useNavigate()

    // useEffect(() => {
    //     if (token) {
    //         navigate(`${consts.mainUrl}/dashboard`)
    //     }
    // }, [token])
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [cnfPassword, setCnfPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [cnfPasswordError, setCnfPasswordError] = useState("")

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;

    const submitresetpassword= async()=> {
        if(email === ""){
            toast.error("OTP Should Not Be Empty")
        }
        if(password === ""){
            toast.error("Password Should Not Be Empty")
        }
        if(cnfPassword === ""){
            toast.error("Confirm-Password Should Not Be Empty")
        }else{
            var payload = {
                otpcode:email,
                password: cnfPassword,
            }
            const { data } = await Axios.post('/forgetverify', payload); 
            if (data?.success === true) {
                toast.success("Password Updated Successfully");
                setEmail("")
                setCnfPassword("")
                setPassword("")
                setPasswordError("")
                setCnfPasswordError("")
                setTimeout(() => {
                    navigate('/login')
                }, 100);
            } else {
                toast.error(data?.message);
            }
        }
    }


    const checkpass = (e)=> {
        console.log(e,"vijay");
        if(!passwordRegex.test(e)){
            setPasswordError("Password Must Contain MIN 8 Character , One ALPHABET And Must Contain One Number ")
        }else{
            setPasswordError("")
        }
        
    }


    const checkconfirmpass = (e)=> {
        if(password != e){
            setCnfPasswordError("Password Mismatching")
        }else{
            setCnfPasswordError("")
        }
        
    }

    return (
        <div className='auth-page' style={{ height: '100vh', overflow: 'hidden' }}>

            <Grid container spacing={0} style={{ height: '100%' }} alignItems={'center'}>
                <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
                    <div className="login-left-part">
                        <div className="logo-part">
                            <Link to='/'>
                                <img src={Logo} alt="click&trade" style={{ width: '250px' }} className='log-logo' />
                            </Link>
                        </div>

                        <div className='login-grid'>
                            <div className="auth-form">

                                <div className="twofa-title text-center">
                                    Reset Password
                                </div>

                                <div className='email-filed phr'>
                                <TextField placeholder='Enter OTP'
                                        onChange={(e) => setEmail(e.target.value)}
                                        type='number'
                                        // type={showPasswordotp ? 'number' : 'password'}
                                        slotProps={{
                                            input: {
                                                startAdornment: <InputAdornment position="start">
                                                     <IconButton
                                                        aria-label="toggle password visibility"
                                                        // onClick={handleClickShowPasswordotp}
                                                        edge="start"
                                                    >
                                                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.65 7.12915C13.4625 7.12915 14.15 7.81665 14.15 8.62915V14.6292C14.15 15.4729 13.4625 16.1292 12.65 16.1292H1.65004C0.80629 16.1292 0.15004 15.4729 0.15004 14.6292V8.62915C0.15004 7.81665 0.80629 7.12915 1.65004 7.12915H2.40004V4.87915C2.40004 2.2854 4.52504 0.12915 7.15004 0.12915C9.74379 0.12915 11.9 2.2854 11.9 4.87915V7.12915H12.65ZM9.40004 7.12915V4.87915C9.40004 3.6604 8.36879 2.62915 7.15004 2.62915C5.90004 2.62915 4.90004 3.6604 4.90004 4.87915V7.12915H9.40004Z" fill="#15BD6F" />
                                                    </svg>
                                                     
                                                    </IconButton>
                                                </InputAdornment>,
                                            },
                                        }}
                                        // type='number'

                                    />
                                </div>
                       

                                <div className='email-filed phr'>
                                    {/* <label className='display-1 fllname'>Enter a password </label> */}
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            value={password}
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Enter New password'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {!showPassword ? <VisibilityOff sx={{ fill: '#fff' }} /> : <Visibility sx={{ fill: '#fff' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="start"
                                                    >
                                                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.6014 7.01721C13.4139 7.01721 14.1014 7.70471 14.1014 8.51721V14.5172C14.1014 15.361 13.4139 16.0172 12.6014 16.0172H1.60138C0.757629 16.0172 0.101379 15.361 0.101379 14.5172V8.51721C0.101379 7.70471 0.757629 7.01721 1.60138 7.01721H2.35138V4.76721C2.35138 2.17346 4.47638 0.0172119 7.10138 0.0172119C9.69513 0.0172119 11.8514 2.17346 11.8514 4.76721V7.01721H12.6014ZM9.35138 7.01721V4.76721C9.35138 3.54846 8.32013 2.51721 7.10138 2.51721C5.85138 2.51721 4.85138 3.54846 4.85138 4.76721V7.01721H9.35138Z" fill="#15BD6F" />
                                                        </svg>

                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(e) => { setPassword(e.target.value); checkpass(e.target.value) }}

                                        />
                                    </FormControl>
                                    {/* <div className='forget-link fllname mt-10'><Link to=''>Forgot Password?</Link></div> */}
                                    <div className="err-div mt-10">{passwordError}</div>
                                </div>

                                <div className='email-filed phr'>
                                    {/* <label className='display-1 fllname'>Re-enter your password</label> */}
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            value={cnfPassword}
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Re-enter New password'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {!showPassword ? <VisibilityOff sx={{ fill: '#fff' }} /> : <Visibility sx={{ fill: '#fff' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="start"
                                                    >
                                                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.6014 7.01721C13.4139 7.01721 14.1014 7.70471 14.1014 8.51721V14.5172C14.1014 15.361 13.4139 16.0172 12.6014 16.0172H1.60138C0.757629 16.0172 0.101379 15.361 0.101379 14.5172V8.51721C0.101379 7.70471 0.757629 7.01721 1.60138 7.01721H2.35138V4.76721C2.35138 2.17346 4.47638 0.0172119 7.10138 0.0172119C9.69513 0.0172119 11.8514 2.17346 11.8514 4.76721V7.01721H12.6014ZM9.35138 7.01721V4.76721C9.35138 3.54846 8.32013 2.51721 7.10138 2.51721C5.85138 2.51721 4.85138 3.54846 4.85138 4.76721V7.01721H9.35138Z" fill="#15BD6F" />
                                                        </svg>

                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(e) => { setCnfPassword(e.target.value); checkconfirmpass(e.target.value) }}

                                        />
                                    </FormControl>
                                    {/* <div className='forget-link fllname mt-10'><Link to=''>Forgot Password?</Link></div> */}
                                    <div className="err-div mt-10">{cnfPasswordError}</div>
                                </div>

                                <div className='form-submit margin-t-30px'>
                                    <Button variant='contained' onClick={submitresetpassword} > Reset Password</Button>
                                </div>

                                <div className="register-acctn">
                                    <p className='reg-free'>Alreadyhave an account?  <NavLink className='reg-free' to='/login'>Log-in</NavLink></p>
                                </div>
                            </div>

                        </div>

                    </div>
                </Grid>

                {mdScreen && <Grid item xs={12} sm={12} md={6} lg={8} xl={8}></Grid>}

            </Grid>
            <Toaster />
        </div >
    )
}

export default ResetPaswd
