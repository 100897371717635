
import Axios from "../Components/Axios";



const logout = async () => {

    try {
        const payload = {
            time: new Date().getTime()
        }
        const { data } = await Axios.post('/session', payload, {
            headers: {
                Authorization: localStorage.getItem('Rikosta')
            }
        })
        if (data.success) {
            return true
        }
        else {
            return false
        }

    } catch (error) {
        console.log(error, 'err');
        return false
    }
}

export default logout