import React, { useContext } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import FooterLogo from "../../Images/footer-logo.png";
import FooterLogolight from "../../Images/ftlight.webp";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import { ThemeContext } from '../DarkMode/ThemeProvider';

const Footer = () => {
  const { isLightMode, handleDarkmode } = useContext(ThemeContext);

  return (
    <div className="footer-full">
      <div className="contain-width">
        <div className="footer-logo">
          <img src={isLightMode ? FooterLogolight : FooterLogo} alt="Logo" />
        </div>
        <div className="footer-content display-2">
          <p>Lorem ipsum dolor sit amet consectetur. Eget.</p>
          <p>Clicks & trade © 2024 All rights reserved</p>
          <div>
            <Link to="">Terms & Policies</Link>
          </div>
        </div>
        <div className="socail-media display-1">
          <p>Follow Us</p>

          <Link to="">
            <FacebookOutlinedIcon />
          </Link>
          <Link to="">
            <TelegramIcon />
          </Link>

        </div>
      </div>
    </div>
  );
};

export default Footer;
