import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import './Login/Login.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "../../Images/logo.webp";
import { useNavigate, NavLink } from 'react-router-dom';
import Axios from '../Axios.js';
// import toast from "react-hot-toast";
import { Button } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';


const ForgetPaswd = () => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));


    var token = window.localStorage.getItem('Sankar')


    const navigate = useNavigate()

    // useEffect(() => {
    //     if (token) {
    //         navigate(`${consts.mainUrl}/dashboard`)
    //     }
    // }, [token])

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")

    const [status, setStatus] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === "") {
            toast.error("Please Enter Your Email");
            return;
        }

        try {
            setStatus(true);
            const { data } = await Axios.post('/forgot', {
                email: email
            })
            if (data?.success) {
                toast.success("OTP Sent Sucessfully")

                setTimeout(() => {
                    navigate('/reset') 
                }, 100);
               
            } else {
                toast.error(data?.message)
            }
            // Fetch the users from your db.json   forgot
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.error(error);
            setStatus(false);
        }
    };

    return (
        <div className='auth-page' style={{ height: '100vh', overflow: 'hidden' }}>

            <Grid container spacing={0} style={{ height: '100%' }} alignItems={'center'}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className="login-left-part">
                        <div className="logo-part">
                            <Link to='/'>
                                <img src={Logo} alt="click&trade" style={{ width: '250px' }} className='log-logo' />
                            </Link>
                        </div>

                        <div className='login-grid'>
                            <div className="auth-form">
                                <div className="twofa-title text-center">
                                    Forget Password
                                </div>
                                <div className='email-filed'>
                                    {/* <label className='display-1 fllname'>Email</label> */}
                                    <TextField placeholder='Enter email address'
                                        onChange={(e) => setEmail(e.target.value)}

                                        slotProps={{
                                            input: {
                                                startAdornment: <InputAdornment position="start">
                                                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.28261 8.20312C5.06386 8.20312 3.28261 6.42188 3.28261 4.20312C3.28261 2.01562 5.06386 0.203125 7.28261 0.203125C9.47011 0.203125 11.2826 2.01562 11.2826 4.20312C11.2826 6.42188 9.47011 8.20312 7.28261 8.20312ZM10.0639 9.20312C12.3764 9.20312 14.2826 11.1094 14.2826 13.4219V14.7031C14.2826 15.5469 13.5951 16.2031 12.7826 16.2031H1.78261C0.938864 16.2031 0.282614 15.5469 0.282614 14.7031V13.4219C0.282614 11.1094 2.15761 9.20312 4.47011 9.20312H5.00136C5.68886 9.54688 6.47011 9.70312 7.28261 9.70312C8.09511 9.70312 8.84511 9.54688 9.53261 9.20312H10.0639Z" fill="#15BD6F" />
                                                    </svg>


                                                </InputAdornment>,
                                            },
                                        }}
                                    // type='number'

                                    />
                                </div>




                                <div className='form-submit margin-t-30px'>
                                    <Button onClick={handleSubmit} >Send Email</Button>
                                </div>

                                <div className="register-acctn">
                                    <p className='reg-free'>Don’t have an account yet?  <NavLink className='reg-free' to='/register'>Register for free</NavLink></p>
                                </div>
                            </div>

                        </div>

                    </div>
                </Grid>

                {mdScreen && <Grid item xs={12} sm={12} md={6} lg={8} xl={8}></Grid>}

            </Grid>
            <Toaster />

        </div >
    )
}

export default ForgetPaswd
