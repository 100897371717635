import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import './Login.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from "../../../Images/logo.webp";
import { useNavigate, NavLink } from 'react-router-dom';
import Axios from '../../Axios.js';
// import toast from "react-hot-toast";
import { Button } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';


const Login = () => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === "") {
            toast.error("Please Enter Email");
            return;
        }
        if (password === "") {
            toast.error("Please Enter Password");
            return;
        }

        try {

            const { data } = await Axios.post('/login', {
                email: email,
                password: password,
                time: new Date().getTime(),
            })
            if (data?.success) {
                // console.log(data?.result, typeof data?.result?.user?.f2A_Status, "user");
                if (data?.result?.user?.f2A_Status === 'false') {
                    window.localStorage.setItem('Rikosta', data?.result?.token)
                    window.localStorage.setItem('user', JSON.stringify(data?.result?.user))
                    toast.success("Logged In Successfully")

                    setTimeout(() => {
                        navigate('/')
                    }, 100);
                } else {
                    setTimeout(() => {
                        navigate('/2fa', { state: data?.result?.token })
                    }, 100);
                }


            } else {
                toast.error(data?.message)
            }

        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.error(error);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('Rikosta') != null) {
            navigate('/')
        }
    }, [])

    return (
        <div className='auth-page' style={{ height: '100vh', overflow: 'hidden' }}>

            <Grid container spacing={0} style={{ height: '100%' }} alignItems={'center'}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className="login-left-part">
                        <div className="logo-part">
                            <Link to='/'>
                                <img src={Logo} alt="click&trade" style={{ width: '250px' }} className='log-logo' />
                            </Link>
                        </div>

                        <div className='login-grid'>
                            <div className="auth-form">
                                <div className='email-filed'>
                                    <label className='display-1 fllname'>Email</label>
                                    <TextField placeholder='Enter email address'
                                        onChange={(e) => setEmail(e.target.value)}

                                        slotProps={{
                                            input: {
                                                startAdornment: <InputAdornment position="start">
                                                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.28261 8.94116C5.06386 8.94116 3.28261 7.15991 3.28261 4.94116C3.28261 2.75366 5.06386 0.941162 7.28261 0.941162C9.47011 0.941162 11.2826 2.75366 11.2826 4.94116C11.2826 7.15991 9.47011 8.94116 7.28261 8.94116ZM10.0639 9.94116C12.3764 9.94116 14.2826 11.8474 14.2826 14.1599V15.4412C14.2826 16.2849 13.5951 16.9412 12.7826 16.9412H1.78261C0.938864 16.9412 0.282614 16.2849 0.282614 15.4412V14.1599C0.282614 11.8474 2.15761 9.94116 4.47011 9.94116H5.00136C5.68886 10.2849 6.47011 10.4412 7.28261 10.4412C8.09511 10.4412 8.84511 10.2849 9.53261 9.94116H10.0639Z" fill="#15BD6F" />
                                                    </svg>
                                                </InputAdornment>,
                                            },
                                        }}

                                    />
                                </div>



                                <div className='email-filed phr'>
                                    <label className='display-1 fllname'>Password</label>
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Password'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {!showPassword ? <VisibilityOff sx={{ fill: '#fff' }} /> : <Visibility sx={{ fill: '#fff' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="start"
                                                    >
                                                        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.6014 7.01721C13.4139 7.01721 14.1014 7.70471 14.1014 8.51721V14.5172C14.1014 15.361 13.4139 16.0172 12.6014 16.0172H1.60138C0.757629 16.0172 0.101379 15.361 0.101379 14.5172V8.51721C0.101379 7.70471 0.757629 7.01721 1.60138 7.01721H2.35138V4.76721C2.35138 2.17346 4.47638 0.0172119 7.10138 0.0172119C9.69513 0.0172119 11.8514 2.17346 11.8514 4.76721V7.01721H12.6014ZM9.35138 7.01721V4.76721C9.35138 3.54846 8.32013 2.51721 7.10138 2.51721C5.85138 2.51721 4.85138 3.54846 4.85138 4.76721V7.01721H9.35138Z" fill="#15BD6F" />
                                                        </svg>

                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(e) => setPassword(e.target.value)}

                                        />
                                    </FormControl>
                                    <div className='forget-link fllname mt-10'><Link to='/forgot'>Forgot Password?</Link></div>
                                </div>
                                <div className='form-submit margin-t-30px'>
                                    <Button onClick={handleSubmit} >Login</Button>
                                </div>

                                <div className="register-acctn">
                                    <p className='reg-free'>Don’t have an account yet?  <NavLink className='reg-free' to='/register'>Register for free</NavLink></p>
                                </div>
                            </div>

                        </div>

                    </div>
                </Grid>

                {mdScreen && <Grid item xs={12} sm={12} md={6} lg={8} xl={8}></Grid>}

            </Grid>
            <Toaster />

        </div>
    )
}

export default Login
