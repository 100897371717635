import React, { useEffect, useState ,useRef} from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Market.css";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import BTC from "../../Images/coin-1.svg";
import Drawer from '@mui/material/Drawer';
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import star from "../../Images/star.webp";
import { useNavigate } from 'react-router-dom';
import Green from "../../Images/green-chart-1.svg";
import Red from "../../Images/red-chart-1.svg";
import { Link, Navigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Button, Grid2 } from "@mui/material";
import btc from "../../Images/btc.webp";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import bnc from '../../Images/bnc.png';
import okx from '../../Images/okx.png';
import bybit from '../../Images/bybit.jpg';
import Stack from '@mui/material/Stack';
import Axios from "../Axios";
import $ from 'jquery'
import Pagination from '@mui/material/Pagination';
import toast from "react-hot-toast";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "600px",
  overflowY: "scroll",
  background: 'linear-gradient(113.81deg, #000000 1.84%, #051930 96.08%) !important',
  // border: '2px solid #000',
  // boxShadow: 24,
  borderRadius: '12px',
  p: 4,
};



const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "400px",
  height: "400px",
  overflowY: "scroll",
  background: 'linear-gradient(113.81deg, #000000 1.84%, #051930 96.08%) !important',
  // border: '2px solid #000',
  // boxShadow: 24,
  borderRadius: '12px',
  p: 4,
};
const ws = new WebSocket("wss://stream.binance.com:9443/ws");




const Market = () => {
  const navigate = useNavigate()
  const [openLoad, setOpenLoad] = useState(true);
  const handleCloseLoad = () => {
    setOpenLoad(false);
  };
  const handleOpenLoad = () => {
    setOpenLoad(true);
  };



  const apiCall = {
    "method": "SUBSCRIBE",
    "params": [
      "btcusdt@ticker",
      "bnbusdt@ticker",
      "ethusdt@ticker"
    ],
    "id": 1
  }

  const [datatusd, setDatatusd] = useState({})
  const [databnb, setDatabnb] = useState([])
  const [datausdc, setDatausdc] = useState([])
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [changePage, setChangePage] = useState(false)
  const [search, setSearch] = useState()
  const [coin, setCoin] = useState('USDT')
  const [exchange, setExchange] = useState('binance')
  const [basevalue, setBasevalue] = useState("")
  const [hrhigh, setHrhigh] = useState("")
  const [hrlow, setHrlow] = useState("")
  const [nocoin , setNocoin] = useState("")
  const [symbol, setSymbol] = useState([])
  const [logicstate, setLogicstate] = useState([])
  const [resstate, setResstae] = useState([])
  const [resstate2, setResstae2] = useState(false)
  // const [ basevalue , setBasevalue ] = useState("")

  const [load, setLoad] = useState(false)

  // console.log(symbol, 'symbol');
  const [simple, setSimple] = useState([])

  const [perPage] = useState(10);
  const handleChange1 = (event, value) => {
    setPage(value);
    setChangePage(true)
    // const ws1 = new WebSocket("wss://stream.binance.com:9443/ws");

    ws.onopen = (event) => {
      const priceApicall = {
        "method": "UNSUBSCRIBE",
        "params": simple,
        "id": 1
      }
      // console.log(priceApicall, 'priceApicall');

      ws.send(JSON.stringify(priceApicall));

    };


    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket Connection Closed');
    };

    priceSocket()

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    };


  };

  const [client, setClient] = useState();


  const socket = () => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(apiCall));
    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);

      try {

        if (json.s === "BTCUSDT") {
          setDatatusd(json)
        }
        else if (json.s === "BNBUSDT") {
          setDatabnb(json)
        }
        else if (json.s === "ETHUSDT") {
          setDatausdc(json)
        }
        //  else if (json.s === "SOLUSDT") {
        //   setDatasol(json)
        // } else if (json.s === "POLUSDT") {
        //   setDatamatic(json)
        // } else if (json.s === "DOTUSDT") {
        //   setDatadot(json)
        // }

      } catch (err) {
        console.log(err);
      }
    };

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    }
  }

  useEffect(() => {
    socket()
    // return () => {
    //   console.log('Cleaning up WebSocket connection');
    //   setTimeout(() => {
    //     // ws.close();
    //   }, 3000);

    // }
  }, [])

  const searchData = useRef(0)

  const searchsocket = () => {
    const ws = new WebSocket("wss://stream.binance.com:9443/ws");



    // const startIndex = (page - 1) * perPage;
    // const endIndex = startIndex + perPage;
    // // console.log(rows, "rowswe");

    // const currentPageData = rows?.slice(startIndex, endIndex) || [];

    const symbols = rows.map(data => `${data.symbol.toLowerCase()}@ticker`);

    // const symbols = rows.map(data => `${data.symbol.toLowerCase()}@ticker`);
    const priceApicall = {
      "method": "SUBSCRIBE",
      "params": symbols,
      "id": 1
    }

    ws.onopen = (event) => {
      ws.send(JSON.stringify(priceApicall));
    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);

      try {

        // setLogicstate(logicstate.map(item=>
        //   item.symbol == json?.s ? { ...item,close:json?.c,openprice:json?.o,volume:json?.v,high:json?.h,low:json.l} : item
        // ))
        var obj = {
          symbol: json.s,
          close: json.c,
          openprice: json?.o,
          volume: json?.v,
          high: json?.h,
          low: json.l
        }

        // if (obj?.close >= searchData) {
        //   console.log(searchData, obj?.close, "searchData")
        //   setResstae(resstate.map(item =>
        //     item.symbol == obj?.symbol ? { ...item, close: json?.c, openprice: json?.o, volume: json?.v, high: json?.h, low: json.l } : {
        //       ...item, obj
        //     }))
        // }
        
        if (obj?.close > searchData.current && searchData.current != 0) {
        
          setResstae((prevResstate) => {
            const existingItem = prevResstate.find(item => item.symbol === obj.symbol);
        
            if (existingItem) {
              // If symbol matches, update the item
              return prevResstate.map(item =>
                item.symbol === obj.symbol
                  ? { ...item, close: json?.c, openprice: json?.o, volume: json?.v, high: json?.h, low: json?.l }
                  : item
              );
            } else {
              // If no match, add the new object
              return [...prevResstate, obj];
            }
          });
        }


      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    searchsocket()
    // console.log(logicstate, "logictsayeg");


  }, [rows])



  const priceSocket = () => {

    const ws = new WebSocket("wss://stream.binance.com:9443/ws");

    ws.onopen = (event) => {
      // console.log('open');

      const startIndex = (page - 1) * perPage;
      const endIndex = startIndex + perPage;
      // console.log(rows, "rowswe");

      const currentPageData = rows?.slice(startIndex, endIndex) || [];

      const symbols = currentPageData.map(data => `${data.symbol.toLowerCase()}@ticker`);
      // console.log(symbols, 'symbols');
      setSymbol(symbols)
      setSimple(symbols)
      const priceApicall = {
        "method": "SUBSCRIBE",
        "params": symbols,
        "id": 1
      }
      // console.log(priceApicall, 'priceApicall');

      ws.send(JSON.stringify(priceApicall));

    };

    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);

      // if (json.s === "STETHUSDT") {
      //   console.log(json,"tradedata");
      // }



      let priceHtml;
      let lowPriceHtml;
      let highPriceHtml;

      try {




        // setLogicstate(data)

        priceHtml = isNaN(json.p) ? '0' : parseFloat(json?.p).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.p).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.p).toFixed(4)}</span>`;

        lowPriceHtml = isNaN(json.l) ? '0' : parseFloat(json?.l).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.l).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.l).toFixed(4)}</span>`;

        highPriceHtml = isNaN(json.h) ? '0' : parseFloat(json?.h).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.h).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.h).toFixed(4)}</span>`;

        $(`.price-${json.s}`).html(priceHtml);
        $(`.volume-${json.s}`).html(isNaN(json?.v) ? '0' : parseFloat(json?.v).toFixed(4));
        $(`.openprice-${json.s}`).html(isNaN(json?.o) ? '0' : parseFloat(json?.o).toFixed(4));
        $(`.closeprice-${json.s}`).html(isNaN(json?.c) ? '0' : parseFloat(json?.c).toFixed(4));
        $(`.lowprice-${json.s}`).html(lowPriceHtml);
        $(`.highprice-${json.s}`).html(highPriceHtml);
        $(`.trades-${json.s}`).html(json.n);


      } catch (error) {
        console.log(error, 'err');

      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    ws.onclose = () => {
      console.log('WebSocket Connection Closed');
    };

    return () => {
      console.log('Cleaning up WebSocket connection');
      ws.close();
    };
  }


  const bybitsocker = () => {
    const client =  new WebSocket('wss://stream.bybit.com/v5/public/spot');
    
    client.onopen = () => {
      console.log('WebSocket Client Connected');
      rows.forEach(pairData => {
        client.send(JSON.stringify({ op: 'subscribe', args: [`tickers.${pairData.symbol}`] }));
      });
    };

    client.onmessage = (event) => {
      // console.log('Message received:', event.data);
      const data = JSON.parse(event.data);
      if (data?.topic && data?.data) {
        const symbol = data?.topic?.split('.')[1];
        const response = data?.data;
      let priceHtml;
      let lowPriceHtml;
      let highPriceHtml;

      try {




        // setLogicstate(data)

        priceHtml = isNaN(response?.usdIndexPrice) ? '0' : parseFloat(response?.usdIndexPrice).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(response?.usdIndexPrice).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(response?.usdIndexPrice).toFixed(4)}</span>`;

        lowPriceHtml = isNaN(response?.lowPrice24h) ? '0' : parseFloat(response?.lowPrice24h).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(response?.lowPrice24h).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(response?.lowPrice24h).toFixed(4)}</span>`;

        highPriceHtml = isNaN(response?.highPrice24h) ? '0' : parseFloat(response?.highPrice24h).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(response?.highPrice24h).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(response?.highPrice24h).toFixed(4)}</span>`;

        $(`.price-${response?.symbol}`).html(priceHtml);
        $(`.volume-${response?.symbol}`).html(isNaN(response?.volume24h) ? '0' : parseFloat(response?.volume24h).toFixed(4));
        $(`.openprice-${response?.symbol}`).html(isNaN(response?.price24hPcnt) ? '0' : parseFloat(response?.price24hPcnt).toFixed(4));
        $(`.closeprice-${response?.symbol}`).html(isNaN(response?.prevPrice24h) ? '0' : parseFloat(response?.prevPrice24h).toFixed(4));
        $(`.lowprice-${response?.symbol}`).html(response?.lowPrice24h);
        $(`.highprice-${response?.symbol}`).html(highPriceHtml);
        $(`.trades-${response.symbol}`).html(isNaN(response.n) ? '0' :  parseFloat(response?.c).toFixed(4));


      } catch (error) {
        console.log(error, 'err');

      }
      client.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };
  
      client.onclose = () => {
        console.log('WebSocket Connection Closed');
      };
  
      return () => {
        console.log('Cleaning up WebSocket connection');
        // client.close();
      };

      }
    };



  }

  const okxPriceSocket = () => {
    const okxws = new WebSocket('wss://ws.okx.com:8443/ws/v5/public?brokerId=198')
    let priceHtml;
    let lowPriceHtml;
    let highPriceHtml;
    okxws.onopen = (event) => {
      const startIndex = (page - 1) * perPage;
      const endIndex = startIndex + perPage;
      // console.log(rows, "rowswe");
      const currentPageData = rows?.slice(startIndex, endIndex) || [];
      const symbols = currentPageData.map(data => {
        return (
          {
            "channel": "tickers",
            "instId": data?.baseCoin + "-" + data?.quoteCoin
          }
        )
      })
      // setSymbol(symbols)

      // setSimple(symbols)

      const priceApicall = {
        "op": "subscribe",
        "args": symbols
        // [

        //   {
        //     "channel": "tickers",
        //     "instId": "BTC-USDT"
        //   }

        // ]
      }
      // console.log(priceApicall, 'priceApicall');
      okxws.send(JSON.stringify(priceApicall));
    }
    okxws.onmessage = function (event) {
      // console.log("🚀  okxPriceSocket  event:", JSON.parse(event.data));
      var response = JSON.parse(event.data);
      if (response?.data?.length > 0) {
        var json = response?.data[0];

        priceHtml = isNaN(json.last) ? '0' : parseFloat(json?.last).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.last).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.last).toFixed(4)}</span>`;

        lowPriceHtml = isNaN(json.low24h) ? '0' : parseFloat(json?.low24h).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.low24h).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.low24h).toFixed(4)}</span>`;

        highPriceHtml = isNaN(json.high24h) ? '0' : parseFloat(json?.high24h).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.high24h).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.high24h).toFixed(4)}</span>`;

        $(`.price-${json.instId?.split('-').join("")}`).html(priceHtml);
        $(`.volume-${json.instId?.split('-').join("")}`).html(isNaN(json?.vol24h) ? '0' : parseFloat(json?.vol24h).toFixed(4));
        $(`.openprice-${json.instId?.split('-').join("")}`).html(isNaN(json?.open24h) ? '0' : parseFloat(json?.open24h).toFixed(4));
        $(`.closeprice-${json.instId?.split('-').join("")}`).html(isNaN(json?.last) ? '0' : parseFloat(json?.last).toFixed(4));
        $(`.lowprice-${json.instId?.split('-').join("")}`).html(lowPriceHtml);
        $(`.highprice-${json.instId?.split('-').join("")}`).html(highPriceHtml);
        $(`.trades-${json.instId?.split('-').join("")}`).html(json.n);

      }
    }
    okxws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
    okxws.onclose = () => {
      console.log('WebSocket Connection Closed');
    };
    return () => {
      console.log('Cleaning up WebSocket connection');
      okxws.close();
    };
  }

  const mexcPriceSocket = () => {
    console.log("inside1")
    const mexcws = new WebSocket('wss://wbs.mexc.com/ws')
    let priceHtml;
    let lowPriceHtml;
    let highPriceHtml;
    mexcws.onopen = (event) => {
      console.log("inside2")
      const startIndex = (page - 1) * perPage;
      const endIndex = startIndex + perPage;
      // console.log(rows, "rowswe");
      const currentPageData = rows?.slice(startIndex, endIndex) || [];
      const symbols = currentPageData.map(data => {
        return (
          `spot@public.miniticker.v3.api}@UTC+5`
        )
      })
      console.log(symbols, 'symbols');
      // setSymbol(symbols)

      // setSimple(symbols)

      const priceApicall = { "method": "SUBSCRIPTION", "params": symbols }
      // console.log(priceApicall, 'priceApicall');
      mexcws.send(JSON.stringify(priceApicall));
    }
    mexcws.onmessage = function (event) {
      // console.log("🚀  okxPriceSocket  event:", event);
      var response = JSON.parse(event.data);
      console.log("🚀  mexcPriceSocket  response:", response)
      if (response?.d) {
        var json = response?.d;

        priceHtml = isNaN(json.p) ? '0' : parseFloat(json?.p).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.p).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.p).toFixed(4)}</span>`;

        lowPriceHtml = isNaN(json.l) ? '0' : parseFloat(json?.l).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.l).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.l).toFixed(4)}</span>`;

        highPriceHtml = isNaN(json.h) ? '0' : parseFloat(json?.h).toFixed(4) > 0 ?
          `<span class="classgreens">${parseFloat(json?.h).toFixed(4)}</span>` :
          `<span class="classreds">${parseFloat(json?.h).toFixed(4)}</span>`;

        $(`.price-${json.response?.s}`).html(priceHtml);
        $(`.volume-${json.response?.s}`).html(isNaN(json?.v) ? '0' : parseFloat(json?.v).toFixed(4));
        $(`.openprice-${json.response?.s}`).html(isNaN(json?.open24h) ? '0' : parseFloat(json?.open24h).toFixed(4));
        $(`.closeprice-${json.response?.s}`).html(isNaN(json?.p) ? '0' : parseFloat(json?.p).toFixed(4));
        $(`.lowprice-${json.response?.s}`).html(lowPriceHtml);
        $(`.highprice-${json.response?.s}`).html(highPriceHtml);
        $(`.trades-${json.response?.s}`).html(json.n);

      }
    }
    mexcws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
    mexcws.onclose = () => {
      console.log('WebSocket Connection Closed');
    };
    return () => {
      console.log('Cleaning up WebSocket connection');
      mexcws.close();
    };
  }

  useEffect(() => {

    if (rows?.length > 0) {
      // if (client) {
      //   client.close();
      // }

      // const newClient = new WebSocket("wss://stream.binance.com:9443/ws");
      // setClient(newClient);
      if(exchange === "bybit"){
        bybitsocker()
      }else if( exchange === "binance"){
        priceSocket()
      }else if( exchange === "okx"){
        okxPriceSocket()
      }else if(exchange === "mexc"){
        mexcPriceSocket()
      }
      
      
    }
    // return () => {
    //   console.log('socket cleanup');
    //   // ws1.close()
    // }
  }, [page, rows])
  // console.log(datausdc, "dara")

  const [open, setOpen] = useState(false);
  const handleOpen = (e) =>{ 
    // setOpen(true);
    const data = e.replace("USDT","")
   window.open(`https://www.binance.com/en/trade/${data}_USDT?type=spot`,"_blank")
  }
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const locate = useLocation()

  const path = locate.pathname


  const [state, setState] = useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => async(event) => {

    const { data } = await Axios.get('/users/subscribedPlan', {
      headers: {
        Authorization: localStorage.getItem('Rikosta')
      }
    })
    if(data.success === false){
      toast.error("Please subscribe any plans")
      setTimeout(() => {
        navigate("/profile",{state:"1"})
      }, 1000);
    }else{
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      // console.log(({ ...state, [anchor]: open }), 'anchor');
  
      setState({ ...state, [anchor]: open });
    }
  };

  const [age, setAge] = React.useState(10);

  // console.log(rows, 'rows');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const CustomDropdownIcon = () => {
    return <KeyboardArrowDownIcon style={{ width: '18px', height: '12px' }} />;
  };

  const [value, setValue] = React.useState(30);

  const handleChangeSlider = (event, newValue) => {
    setValue(newValue);
  };

  const [sliderData, setSliderData] = useState([
    { name: 'Prijs verandering' },
    { name: 'Volume stijging' },
    { name: 'Prijs onder' },
    { name: 'Volume boven' },
    { name: 'Binnen de laatste' },
    { name: '24h % boven' },
    { name: 'Factor boven' },
    { name: 'Gevaar onder' },
    { name: 'Tokens overslaan' },
  ])

  const handleOptionChange = async (e) => {
    // console.log(e.target.value, 'e');
    setPage(1)
    setCoin(e.target.value)
  }

  const handleExchange = async (e) => {
    setPage(1)
    setExchange(e.target.value)
  }

  const getPairs = async () => {
    try {
      const { data } = await Axios.post('/getPairs', { coin: coin, exchange: exchange }, {
        headers: {
          Authorization: localStorage.getItem('Rikosta')
        }
      })
      if (data.success) {

        if (search) {
          const filtered = [];
          for (let i = 0; i < data.result.length; i++) {

            if (data.result[i].symbol.includes(search)) {
              filtered.push(data.result[i]);
            }
          }
          setRows(filtered);

        }
        else {
          for (let i = 0; i < data.result.length; i++) {
            // console.log(data.result.length, "apidkadfiadgy");

            let da = {
              image: data.result[i].imageurl,
              close: "0",
              symbol: data.result[i].symbol,
              openprice: "0",
              volume: "0",
              high: "0",
              low: "0",

            }
            setLogicstate(logicstate => [...logicstate, da])

          }



          setRows(data.result)
        }
      }
    }
    catch (error) {
      console.log(error, 'err');
    }
  }

  // useEffect(() => {
  //   if (path === '/market') {
  //     const timer = setTimeout(() => {
  //       // console.log('logged after 1sec');
  //       setState({ top: true });
  //     }, 10);

  //     return () => clearTimeout(timer)
  //   }
  // }, [path])

  useEffect(() => {
    if (rows?.length > 0) {
      const timer = setTimeout(() => {
        // console.log('logged after 5sec');
        setChangePage(false)
      }, 3000);

      return () => clearTimeout(timer)
    }
  }, [page])

  useEffect(() => {
    getPairs()
  }, [coin, search, exchange])


  // const algorithm = async () => {
  //   setLoad(true)
  //   const timer = setTimeout(() => {
  //     setLoad(false)
  //     setResstae2(true)
  //   }, 3000);

  // }
  const algorithm = async () => {
    setLoad(true)
    const timer = setTimeout(() => {
      setLoad(false)
      setResstae2(true)
    }, 3000);
    if (hrhigh === "") {
      toast.error("value should not be empty")
    }
    else if (hrlow === "") {
      toast.error("value should not be empty")
    } else if( nocoin === ""){
      toast.error("No.of.coin To Display Should Not Be Empty")
    }
    else {

      let sub = hrhigh - hrlow
      let div = sub / hrhigh
      let mul = div * 100
      // console.log(mul, "mul");

      if (mul >= 3) {
        searchData.current = Number(mul)
                // console.log(parseFloat(mul).toFixed(3),"vijay")
        // for (let i = 0; i < logicstate.length; i++) {
        //   console.log(logicstate.length,"lengdath");
        //   const element = logicstate[i];

        //   console.log(element,"elemet");
        //   if(mul <= element.close ){
        //     setResstae(resstate =>[...resstate,element])
        //   }
        // }


      } else {
        toast.error("No Pairs Found")
      }



      // console.log(hrhigh, hrlow, "value");
    }

    return () => clearTimeout(timer)

  }


  const valuechange = (e) => {
    console.log(e.target.value, "target")
    if (e.target.value === "1") {
      setBasevalue("BTC")
    } else if (e.target.value === "2") {
      setBasevalue("USDT")
    } else if (e.target.value === "3") {
      setBasevalue("ETH")
    } else if (e.target.value === "4") {
      setBasevalue("USDC")
    }

  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {

    // console.log(name.split(""), typeof name, 'namesss');

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      children: `${name?.split('')[0]}`,

    };
  //  const valuechange = (e)=>{
  //     setBasevalue(e.target.value)
  //  }
}

  const handleCloseAnchor = () => {
    setState({ top: false })

    setLoad(true)
    const timer = setTimeout(() => {
      setLoad(false)
      setResstae2(false)
    }, 3000);
    return () => clearTimeout(timer)

  }


  return (
    <div className="market-full">
      <Header />
      {['top'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="text-center mark-fil">

            <Button onClick={toggleDrawer(anchor, true)} sx={{ marginTop: '-20px' }}><img className="float" width="50" height="50" src="https://img.icons8.com/plasticine/50/circled-chevron-down.png" alt="circled-chevron-down" /></Button>
          </div>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="top-drawer"
          >
            {/* {list(anchor)} */}
            <div className="market-filterpopup">
              <Grid2 container>
                <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <div className="text-file">
                    <TextField id="outlined-basic" placeholder="24hr high" variant="outlined" type="number" onChange={(e) => setHrhigh(e.target.value)} />
                  </div>

                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <div className="text-file">
                    <TextField id="outlined-basic" placeholder="24hr low" variant="outlined" type="number" onChange={(e) => setHrlow(e.target.value)} />
                  </div>

                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <div className="text-file">
                    <TextField id="outlined-basic" placeholder="No of Coins" variant="outlined" type="number" onChange={(e) => setNocoin(e.target.value)} />
                  </div>

                </Grid2>
                <Grid2 item size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>


                  <div className="select-part display-1">

                    {/* <Select native id="grouped-native-select" onChange={(e) => { valuechange(e) }} >
              <optgroup label="Select">
                <option value={'BTC'}>BTC</option>
                <option value={'USDT'}>USDT</option>
                <option value={'ETH'}>ETH</option>
                <option value={'USDC'}>USDC</option>
              </optgroup>
            </Select> */}

                  </div>


                </Grid2>





                  <div className="select-part display-1">

                    {/* <Select native id="grouped-native-select" onChange={(e) => valuechange(e)} >
                      <optgroup label="Select">
                        <option value={1}>BTC</option>
                        <option value={2}>USDT</option>
                        <option value={3}>ETH</option>
                        <option value={4}>USDC</option>
                      </optgroup>
                    </Select> */}

                  </div>
                </Grid2>
              <div className="display-1">
                <div className="save-set  mt-20">
                  <Button variant="contained" onClick={algorithm}  >Start watching</Button>
                </div>
                <div className="save-set  mt-20">
                  <Button variant="contained" onClick={handleCloseAnchor}  >Close</Button>
                </div>
              </div>


              <div className="text-center" style={{ width: '100%' }} >
                <Button onClick={() => { toggleDrawer(anchor, false); setState({ top: true ? false : true }); }} sx={{ marginTop: '-20px' }} >
                  <img className="float x2" width="50" height="50" src="https://img.icons8.com/plasticine/50/circled-chevron-down.png" alt="circled-chevron-down" />
                </Button>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
      <div className="contain-width">
        <div className="market-count display-2">
          <div className="trade-coin display-1">
            <div className="coin-img">
              <img src={BTC} alt="btc" />
            </div>
            <div className="coin-img">
              <p className="display-1">
                BTC/USDT{datatusd.P > 0 ? <span className="green-bg">+{datatusd?.P ? datatusd?.P : '0'}%</span> : <span className="red-bg">{datatusd?.P ? datatusd?.P : '0'}%</span>}  {/* price change percentage */}
              </p>
              {datatusd.c === undefined ? <p> 0 </p> : <p>{parseFloat(datatusd?.c).toFixed(4)}</p>}  {/* last price */}
              {datatusd.x === undefined ? <p> 0 </p> : <p>{parseFloat(datatusd?.x).toFixed(4)}</p>} {/* first trade price before 24hr */}

              {/* <p>36,641.20</p> */}
            </div>
            <div>
              {/* <SparkLineChart
                data={[1, 1, 2, 5, 7, 2, 4, 6, 5, 5, 2]}
                height={100}
              /> */}
              {datatusd.P > 0 ? <img src={Green} alt="green-chart" /> : <img src={Red} alt="red-chart" />}
            </div>
          </div>
          <div className="trade-coin display-1">
            <div className="coin-img">
              <img src={BTC} alt="btc" />
            </div>
            <div className="coin-img">
              <p className="display-1">
                BNB/USDT {databnb.P > 0 ? <span className="green-bg">+{databnb?.P ? databnb?.P : '0'}%</span> : <span className="red-bg">{databnb?.P ? databnb?.P : "0"}%</span>}  {/* price change percentage */}
              </p>
              {databnb.c === undefined ? <p> 0 </p> : <p>{parseFloat(databnb?.c).toFixed(4)}</p>} {/* last price */}
              {databnb.x === undefined ? <p> 0 </p> : <p>{parseFloat(databnb?.x).toFixed(4)}</p>} {/* first trade price before 24hr */}

              {/* <p>36,641.20</p> */}
            </div>
            <div>
              {/* <SparkLineChart
                data={[1, 1, 2, 5, 7, 2, 4, 6, 5, 5, 2]}
                height={100}
              /> */}
              {databnb.P > 0 ? <img src={Green} alt="green-chart" /> : <img src={Red} alt="red-chart" />}
            </div>
          </div>
          <div className="trade-coin display-1">
            <div className="coin-img">
              <img src={BTC} alt="btc" />
            </div>
            <div className="coin-img">
              <p className="display-1">
                ETH/USDT {datausdc.P > 0 ? <span className="green-bg">+{datausdc?.P ? datausdc?.P : '0'}%</span> : <span className="red-bg">{datausdc?.P ? datausdc?.P : '0'}%</span>}  {/* price change percentage */}
              </p>
              {datausdc.c === undefined ? <p> 0 </p> : <p>{parseFloat(datausdc?.c).toFixed(4)}</p>} {/* last price */}
              {datausdc.x === undefined ? <p> 0 </p> : <p>{parseFloat(datausdc?.x).toFixed(4)}</p>} {/* first trade price before 24hr */}

              {/* <p>36,641.20</p> */}
            </div>
            <div>
              {/* <SparkLineChart
                data={[1, 1, 2, 5, 7, 2, 4, 6, 5, 5, 2]}
                height={100}
              /> */}
              {datausdc.P > 0 ? <img src={Green} alt="green-chart" /> : <img src={Red} alt="red-chart" />}
            </div>
          </div>
        </div>

        <div className="search-list display-2">
          <div className="search-bar display-1">
            <SearchIcon />
            <TextField placeholder="Search" onChange={(e) => {
              setSearch(e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase());
              setPage(1)
            }} />
          </div>
          <div>
            <p>24h Verandering</p>
          </div>
          <div className="select-part display-1">
            {/* <Select native id="grouped-native-select">
              <optgroup label="SORTEER OPTIES">
                <option value={1}>Token</option>
                <option value={2}>Prijs</option>
                <option value={3}>Factor</option>
                <option value={4}>Percent</option>
                <option value={4}>Volume</option>
                <option value={4}>Trades</option>
                <option value={4}>Favorieten</option>
              </optgroup>
            </Select> */}
            <Select native id="grouped-native-select" onChange={(e) => { handleExchange(e) }}>
              <optgroup>
                <option value={'binance'}>Binance</option>
                <option value={'bybit'}>Bybit</option>
                <option value={'okx'}>OKX</option>
                <option value={'mexc'}>MEXC</option>
                {/* <option value={2}>50 tokens</option>
                <option value={2}>100 tokens</option>
                <option value={2}>All tokens</option> */}
              </optgroup>
            </Select>
            <Select native id="grouped-native-select" onChange={(e) => { handleOptionChange(e) }}>
              <optgroup >
                <option value={'USDT'}>USDT</option>
                <option value={'BTC'}>BTC</option>
                <option value={'ETH'}>ETH</option>
                <option value={'USDC'}>USDC</option>
                {/* <option value={"EUR"}>EUR</option> */}
                {/* <option value={"DAI"}>DAI</option> */}
              </optgroup>
            </Select>
            {/* <Select native id="grouped-native-select">
              <optgroup label="SORTEER OPTIES">
                <option value={1}>Option 1</option>
                <option value={2}>Option 2</option>
              </optgroup>
            </Select> */}
          </div>
        </div>


        {load ? <div class="card" style={{ height: '50vh' }}>
          <div class="loader">
            <p>Fetching..!</p>
            <div class="words">
              <span class="word display-1">
                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/btc.svg" alt="bitcoin-exchange" />
                BTC
              </span>
              <span class="word display-1">
                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/usdt.svg" alt="bitcoin-exchange" />
                USDT

              </span>
              <span class="word display-1">
                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/eth.svg" alt="bitcoin-exchange" />
                ETH

              </span>
              <span class="word display-1">
                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/xrp.svg" alt="bitcoin-exchange" />
                XRP

              </span>
              <span class="word display-1">
                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/bnb.svg" alt="bitcoin-exchange" />
                BNB

              </span>
              {/* <span class="word">High Price</span> */}
            </div>
          </div>
        </div> : resstate2 ?
          <>
            <h2 className="text-center">Result Data</h2>
            <div className="custom_table table2">
              <TableContainer>
                <Table aria-label="simple table" className="prf-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Token</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell align="center">Volume</TableCell>
                      <TableCell align="center">Open Price</TableCell>
                      <TableCell align="center">Low Price</TableCell>
                      <TableCell align="center">High Price</TableCell>
                      <TableCell align="center">No of Trades</TableCell>
                      {/* <TableCell align="right">Factor</TableCell>
                  <TableCell align="center">Order Book</TableCell> */}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      resstate?.length > 0 ?

                        !changePage && resstate && [...resstate].slice(0 ,nocoin).map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell>
                              <div className="token_flx">
                                <img src={row.image} />
                                <div>
                                  {/* <p>{row.token}</p> */}
                                  <p>{row.symbol}</p>

                                  {/* <span className="grey">{row.token_short}</span> */}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell >
                              <p>{row.close ? row.close : '0'}</p>
                              {/* <p>{row.price_h}</p> */}
                            </TableCell>
                            <TableCell align="center" >
                              {row.volume ? row?.volume : '0'}
                              {/* <p className="green">{row.volume} </p> */}
                            </TableCell>

                            <TableCell align="center"  >
                              {row.openprice ? row?.openprice : '0'}
                            </TableCell>


                            <TableCell align="center"   >
                              {row.low ? row?.low : '0'}
                            </TableCell>

                            <TableCell align="center"  >
                              {row.high ? row?.high : '0'}
                            </TableCell>

                            <TableCell align="center"   >
                              {row.trades ? row?.trades : '0'}
                            </TableCell>

                            {/* <TableCell align="right">

                      <div className="flx">

                        <img src={row.chart_icon} /> <p>{row.factor} </p>
                      </div>
                    </TableCell>
                    <TableCell align="center">

                      <p>{row.order} </p>{" "}
                      <p className="green"> {row.order_data}</p>{" "}
                    </TableCell>
                  */}
                            <TableCell className="cursor">
                              <img src={star} onClick={()=>handleOpen(row.symbol)} />
                            </TableCell>
                          </TableRow>
                        ))

                        :
                        <TableRow
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell align="center">{'No Data Found'}</TableCell>
                        </TableRow>
                    }

                    {
                      changePage &&
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          <div class="card">
                            <div class="loader">
                              <p>Fetching..!</p>
                              <div class="words">
                                <span class="word display-1">
                                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/btc.svg" alt="bitcoin-exchange" />
                                  BTC
                                </span>
                                <span class="word display-1">
                                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/usdt.svg" alt="bitcoin-exchange" />
                                  USDT

                                </span>
                                <span class="word display-1">
                                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/eth.svg" alt="bitcoin-exchange" />
                                  ETH

                                </span>
                                <span class="word display-1">
                                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/xrp.svg" alt="bitcoin-exchange" />
                                  XRP

                                </span>
                                <span class="word display-1">
                                  <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/bnb.svg" alt="bitcoin-exchange" />
                                  BNB

                                </span>
                                {/* <span class="word">High Price</span> */}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    }

                  </TableBody>
                </Table>
              </TableContainer>

              <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="model-market"
              >
                <Box sx={style}>
                  <div className="text-right">
                    <CancelOutlinedIcon className="cursor" onClick={handleClose} />
                  </div>
                  <div className="mt-20">
                    <div className="display-4" style={{ gap: "80px" }}>
                      <img src={star} alt="star" />

                      <div className="tr-now">
                        <Button variant="contained" onClick={handleOpen2}>
                          Trade Now
                        </Button>
                      </div>
                    </div>
                    <div className="mt-20" >
                      <div className="display-2" style={{ flexFlow: 'wrap' }}>
                        <div className="display-1">
                          <img src={btc} alt="btc" className="coin" />
                          <div className="c-name">
                            BTC #1
                            <div className="c-inner">
                              BTC
                            </div>
                          </div>
                        </div>
                        <div className="c-price">
                          USD WAARDE
                          <div className="c-price-inner">
                            $0.374
                          </div>
                        </div>
                        <div className="c-price">
                          VERANDERING 24H
                          <div className="c-price-inner">
                            $0.374
                          </div>
                        </div>
                        <div className="c-price">
                          BTC24H VOLUME
                          <div className="c-price-inner">
                            $0.374
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ marginTop: "50px" }}>
                      <Grid2 container spacing={3}>

                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              24K HIGH
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              24H LONG
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              BTC HUIDIGE PRIJS
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              BTC GEMIDDELDE 24H
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              MARKET CAP
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              SUPPLY
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              MAX SUPPLY
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                          <div className="in-detail">
                            <div className="in-head">
                              TOTAL VOLUME
                            </div>
                            <div className="mt-10 in-para">
                              0.00001256
                            </div>
                          </div>
                        </Grid2>

                      </Grid2>
                    </div>



                  </div>
                </Box>
              </Modal>

              <Modal
                open={open2}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="model-market"
              >
                <Box sx={style2}>
                  <div className="text-right">
                    <CancelOutlinedIcon className="cursor" onClick={handleClose2} />
                  </div>
                  <div className="mt-20">
                    <Stack spacing={2}>
                      {/* <div className="exchnage-logo">
                    <img src={bnc} alt="bnc" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={okx} alt="okx" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={bybit} alt="bybit" />
                  </div> */}


                    </Stack>
                  </div>
                </Box>
              </Modal>
            </div> </> :

          <div className="custom_table">
            <TableContainer>
              <Table aria-label="simple table" className="prf-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Token</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell align="center">Volume</TableCell>
                    <TableCell align="center">Open Price</TableCell>
                    {/* <TableCell align="center">Close Price</TableCell> */}
                    <TableCell align="center">Low Price</TableCell>
                    <TableCell align="center">High Price</TableCell>
                    <TableCell align="center">No of Trades</TableCell>
                    {/* <TableCell align="right">Factor</TableCell>
                  <TableCell align="center">Order Book</TableCell> */}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rows?.length > 0 ?

                      !changePage && rows && [...rows].slice((page - 1) * perPage, page * perPage).map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell>
                            <div className="token_flx">
                           {!row?.imageurl ? <Avatar sx={{width:48, height:48}}>{row?.baseCoin.split("")[0]}</Avatar>:
                              <img src={row.imageurl} />}
                              <div>
                                {/* <p>{row.token}</p> */}
                                <p>{row.symbol}</p>

                                {/* <span className="grey">{row.token_short}</span> */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={`closeprice-${row?.symbol}`}>
                            <p>{row.close ? row.close : '0'}</p>
                            {/* <p>{row.price_h}</p> */}
                          </TableCell>
                          <TableCell align="center" className={`volume-${row?.symbol}`} >
                            {row.volume ? row?.volume : '0'}
                            {/* <p className="green">{row.volume} </p> */}
                          </TableCell>

                          <TableCell align="center" className={`openprice-${row?.symbol}`}  >
                            {row.open ? row?.open : '0'}
                          </TableCell>

                          {/* <TableCell align="center" className={`closeprice-${row?.symbol}`}  >
                            {row.close ? row?.close : '0'}
                          </TableCell> */}

                          <TableCell align="center" className={`lowprice-${row?.symbol}`}  >
                            {row.low ? row?.low : '0'}
                          </TableCell>

                          <TableCell align="center" className={`highprice-${row?.symbol}`}  >
                            {row.high ? row?.high : '0'}
                          </TableCell>

                          <TableCell align="center" className={`trades-${row?.symbol}`}  >
                            {row.trades ? row?.trades : '0'}
                          </TableCell>

                          {/* <TableCell align="right">

                      <div className="flx">

                        <img src={row.chart_icon} /> <p>{row.factor} </p>
                      </div>
                    </TableCell>
                    <TableCell align="center">

                      <p>{row.order} </p>{" "}
                      <p className="green"> {row.order_data}</p>{" "}
                    </TableCell>
                  */}
                          <TableCell className="cursor">
                            <img src={row.star} onClick={handleOpen} />
                          </TableCell>
                        </TableRow>
                      ))

                      :
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center">{'No Data Found'}</TableCell>
                      </TableRow>
                  }

                  {
                    changePage &&
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <div class="card">
                          <div class="loader">
                            <p>Fetching..!</p>
                            <div class="words">
                              <span class="word display-1">
                                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/btc.svg" alt="bitcoin-exchange" />
                                BTC
                              </span>
                              <span class="word display-1">
                                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/usdt.svg" alt="bitcoin-exchange" />
                                USDT

                              </span>
                              <span class="word display-1">
                                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/eth.svg" alt="bitcoin-exchange" />
                                ETH

                              </span>
                              <span class="word display-1">
                                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/xrp.svg" alt="bitcoin-exchange" />
                                XRP

                              </span>
                              <span class="word display-1">
                                <img width="30" height="30" src="https://www.bybit.com/bycsi-root/assets/image/coins/light/bnb.svg" alt="bitcoin-exchange" />
                                BNB

                              </span>
                              {/* <span class="word">High Price</span> */}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  }

                </TableBody>
              </Table>
            </TableContainer>

            <Modal
              open={open}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="model-market"
            >
              <Box sx={style}>
                <div className="text-right">
                  <CancelOutlinedIcon className="cursor" onClick={handleClose} />
                </div>
                <div className="mt-20">
                  <div className="display-4" style={{ gap: "80px" }}>
                    <img src={star} alt="star" />

                    <div className="tr-now">
                      <Button variant="contained" onClick={handleOpen2}>
                        Trade Now
                      </Button>
                    </div>
                  </div>
                  <div className="mt-20" >
                    <div className="display-2" style={{ flexFlow: 'wrap' }}>
                      <div className="display-1">
                        <img src={btc} alt="btc" className="coin" />
                        <div className="c-name">
                          BTC #1
                          <div className="c-inner">
                            BTC
                          </div>
                        </div>
                      </div>
                      <div className="c-price">
                        USD WAARDE
                        <div className="c-price-inner">
                          $0.374
                        </div>
                      </div>
                      <div className="c-price">
                        VERANDERING 24H
                        <div className="c-price-inner">
                          $0.374
                        </div>
                      </div>
                      <div className="c-price">
                        BTC24H VOLUME
                        <div className="c-price-inner">
                          $0.374
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="" style={{ marginTop: "50px" }}>
                    <Grid2 container spacing={3}>

                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            24K HIGH
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>
                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            24H LONG
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>
                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            BTC HUIDIGE PRIJS
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>
                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            BTC GEMIDDELDE 24H
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>
                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            MARKET CAP
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>
                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            SUPPLY
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>
                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            MAX SUPPLY
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>
                      <Grid2 item size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}>
                        <div className="in-detail">
                          <div className="in-head">
                            TOTAL VOLUME
                          </div>
                          <div className="mt-10 in-para">
                            0.00001256
                          </div>
                        </div>
                      </Grid2>

                    </Grid2>
                  </div>



                </div>
              </Box>
            </Modal>

            <Modal
              open={open2}
              // onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="model-market"
            >
              <Box sx={style2}>
                <div className="text-right">
                  <CancelOutlinedIcon className="cursor" onClick={handleClose2} />
                </div>
                <div className="mt-20">
                  <Stack spacing={2}>
                    {/* <div className="exchnage-logo">
                    <img src={bnc} alt="bnc" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={okx} alt="okx" />
                  </div>
                  <div className="exchnage-logo">
                    <img src={bybit} alt="bybit" />
                  </div> */}


                  </Stack>
                </div>
              </Box>
            </Modal>

            <div className="view-all text-center">
          {/* <Link to="">View all digital assets <ArrowForwardIcon /></Link> */}
          <Pagination count={Math.ceil(rows?.length / perPage)} page={page} onChange={handleChange1} shape="rounded" />

        </div>
          </div>}


      </div>
    </div>
  );
};


export default Market;
